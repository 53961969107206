@font-face {
  font-family: 'Icomoon Regular';
  src: url('bower_components/progress-sass/app/assets/fonts/icomoon.eot');
  src: url('bower_components/progress-sass/app/assets/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
       url('bower_components/progress-sass/app/assets/fonts/icomoon.woff') format('woff'),
       url('bower_components/progress-sass/app/assets/fonts/icomoon.ttf') format('truetype'),
       url('bower_components/progress-sass/app/assets/fonts/icomoon.svg#glyphiconsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon {
  font-family: 'Icomoon Regular', 'Lato', serif;
}

.icomoon-map:before { content: "\E605"; }
.icomoon-template:before { content: "\E61C"; }
.icomoon-users:before { content: "\e60B"; }
.icomoon-office:before { content: "\e600"; }
.icomoon-library:before { content: "\e601"; }
.icomoon-signup:before { content: "\e613"; }
.icomoon-happy:before { content: "\e614"; }
.icomoon-smiley:before { content: "\e615"; }
.icomoon-sad:before { content: "\e616"; }
.icomoon-neutral:before { content: "\e617"; }
.icomoon-wondering:before { content: "\e618"; }
.icomoon-file-pdf:before { content: "\e61d"; }
.icomoon-file-word:before { content: "\e61e"; }
.icomoon-file-excel:before { content: "\e61f"; }
.icomoon-file-powerpoint:before { content: "\e620"; }