.stat-board {
  @extend .list-unstyled;
  @include clearfix();
  background-color: $white;
  border: 1px solid $gray-bright;
  border-radius: 2px;
  box-shadow: 0 2px 0 0 rgba($gray-bright, .5);
  margin-bottom: $margin-sm;
  padding: $margin-sm * 1.5 0;
  &.stat-board-sidebar {
    padding: 0;
    &.demo {
      max-width: 300px;
    }
  }
}

.stat-board-wrapper { //for stat boards with progress bars and indicators
    background: $white;
    border: 1px solid $gray-bright;
    border-radius: 2px;
    box-shadow: 0 2px 0 0 rgba($gray-bright, .5);
    margin-bottom: $margin-sm;
  .loading-bar-wrapper {
        @include clearfix();
        padding: $margin-base $margin-sm $margin-sm $margin-sm;
        position: static;
        .progress-indicator {
          background-color: $pink-base;
          height: $margin-sm * 1.5;
          margin-top: $margin-xxs;
          position: absolute;
          width: $margin-xs / 2;
        }
    }
    .stat-board {
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
        padding: $margin-base 0 0 0;
    }
    &.panel {
      .panel-header {
        @include clearfix();
        .panel-nav {
          margin-bottom: 0;
          .stat-board-timeframe {
            background: $input-bg;
            border-color: $input-border-color;
            border-radius: $input-border-radius;
            border-style: $input-border-style;
            border-width: $input-border-width;
            box-shadow: $input-box-shadow;
            font-family: $input-font-family;
            font-size: $input-sm-font-size;
            font-weight: $input-font-weight;
            height: ($input-sm-padding-v* 2) + $input-sm-font-size + 4;
            line-height: $input-line-height;
            max-width: 100%;
            padding: $input-sm-padding-v $input-sm-padding-h;
            position: relative;
            text-rendering: optimizeLegibility;
            width: 100%;
          }
        }
      }
      .stat-board {
        padding: 0;
      }
    }
}

.stat-item {
  float: left;
  padding: 0 $margin-sm * 1.5;
  & + .stat-item {
    border-left: 1px solid $gray-bright;
  }
  .stat-board-six & {
    width: 16.66%;
  }
  .stat-board-five & {
    width: 20%;
  }
  .stat-board-four & {
    width: 25%;
  }
  .stat-board-three & {
    width: 33.33%
  }
  .stat-board-sidebar & {
    float: none;
    padding: $margin-sm 0;
    width: 100%;
    & + .stat-item {
      border-left: none;
      border-top: 1px solid $gray-bright;
    }
  }
}

.stat-info {
  display: inline-block;
}

.stat-title {
  color: $gray-tinted;
  font-size: $font-size-sm;
  font-weight: $font-weight-semibold;
  line-height: $line-height-base;
}

.stat-data {
  color: $gray-dark;
  font-size: $font-size-xl;
  line-height: $line-height-sm;
}

.stat-arrow {
  font-size: $font-size-lg;
  margin-left: $margin-sm;
  &.glyphicons-chevron-down {
    color: $red-base;
  }
  &.glyphicons-chevron-up {
    color: $green-base;
  }
}

.stat-sub-info {
  color: $gray-tinted;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
}