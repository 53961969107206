//
// NOT TO BE CONFUSED WITH TAbS
// todo: rename?
// =============================================================================

%tag {
  @extend %btn;
  @extend %btn-xs;
  background-color: $gray-bright;
  display: inline-block;
  margin-right: $margin-sm;
  
  &:last-child {
    margin-right: 0;
  }
  
  &:disabled,
  &.disabled {
    opacity: .3;
    
    &:focus,
    &:hover {
      background-color: $gray-bright;
      color: inherit;
      cursor: default;
    }
  }
}

%tag-dynamic {
  &:focus,
  &:hover,
  &.hover {
    background-color: $blue-light;
    color: $blue-dark;
  }
  
  &:active,
  &.pressed {
    background-color: $gray-light;
    color: $gray-base;
  }
  
  &.active {
    background-color: $gray-tinted;
    color: $white;
  }
}

.tag,
.btn-tag {
  @extend %tag, %tag-dynamic;
}

.tag-static {
  @extend %tag;
  color: $white;
  cursor: default;
  text-transform: uppercase;

  &.blue { background-color: $blue-base; }

  &.pink { background-color: $pink-base; }

  &.purple { background-color: $purple-base; }

  &.teal { background-color: $teal-base; }

  &.yellow { background-color: $yellow-base; }
}