//
// Site (to do: clean me)
// =============================================================================

.app-wrapper {
  background: $gray-pale;
  max-width: 100%;
  min-height: 100vh;
//  padding: $app-padding-branding $app-padding-h $app-padding-v;
  width: 100vw;
}


.app-header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  .app-nav-container {
    @include clearfix();
    margin: auto;
    max-width: $app-width;
    position: relative;
    .skip-nav:focus {
      @include transform(translateX(-50%));
      background: $white;
      border: 1px solid $gray-tinted;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: 0px;
      clip: inherit;
      height: auto;
      left: 50%;
      outline-color: $white;
      overflow: auto;
      padding: $margin-sm;
      width: auto;
    }
  }
}

.app-nav-header {
  @include clearfix();
  background-color: $blue-dark;
  box-shadow: 0 0px 2px 0 $blue-base;
  position: relative;
  z-index: 1000;
  &.transparent {
    background-color: transparent;
    box-shadow: none;
    .app-branding {
      float: none;
      margin: auto;
    }
    .app-nav-container {
      text-align: center;
      .app-navigation {
        display: inline-block;
      }
    }
  }
  @media screen and (max-width: $break-sm) {
    padding-left: $margin-base;
  }
  @media screen and (max-width: $break-xs) {
    padding: 0 $margin-base;
  }
}

.app-branding {
  float: left;
}

.app-navigation {
  @extend %list-unstyled;
  @include clearfix;
  > li {
    float: left;
    &.active {
      > a {
        background-color: rgba(0, 0, 0, .1);
      }
    }
    > a {
      color: $white;
      display: block;
      font-size: $font-size-base;
      outline-color: $white;
      padding: ($margin-sm * 1.5 + 3) $margin-base;
      &:focus {
        text-decoration: none;
      }
      &:focus,
      &:hover {
        background-color: rgba(0, 0, 0, .1);
      }
    }
  }
}

.app-user-controls {
  @include clearfix();
  float: right;
  margin: 0;
  padding-left: 0;
  li > a {
    outline-color: $white;
  }
  .dropdown {
    &.open {
      .dropdown-toggle {
        background-color: rgba(0, 0, 0, .1);
      }
    }
    .dropdown-toggle {
      padding: $margin-xxs $margin-base;
    }
  }
}

.app-content {
  margin: auto;
  max-width: $app-width;
  padding: $app-padding-branding $app-padding-h $app-padding-h $app-padding-v;
  .page-header {
    color: $gray-dark;
    font-size: $font-size-xl;
    font-weight: $font-weight-normal;
    margin-bottom: $margin-sm * 1.5;
  }
}

.app {
  background-color: $white;
  box-shadow: 0 2px 0 0 $blue-dark;
  padding: 0 $app-padding-h;
  float: left;
  background-color: $white;
  box-shadow: 0 2px 0 0 $blue-dark;
  padding-left: $app-padding-h;
  padding-right: $app-padding-h;
}

.app-title {
  color: $white;
  display: block;
  font-size: $font-size-xl;
  line-height: 1;
  outline-color: $white;
  padding: $margin-sm * 1.5 $app-padding-h;

  &:focus,
  &:hover {
    color: $white;
  }

  * {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  @media screen and (max-width: $break-xs) {
    padding: $margin-base $margin-xs;
  }
}

.app-nav {
  background-color: $white;
  border-bottom: 2px solid $gray-bright;
  padding: 0 $app-padding-h;
  position: relative;
  z-index: 999;
  .app-nav-container {
    max-width: $app-width - ($margin-base * 2);
  }
  @media screen and (max-width: $break-xs) {
    padding: 0;
  }
}

.app-footer {
  font-size: $font-size-xs;
  padding-bottom: $margin-base;
  text-align: center;
  width: 100%;
}