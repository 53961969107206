//
// Unstyled lists
// =============================================================================

%list-unstyled {
  line-height: inherit;
  list-style: none;
  margin: 0;
  padding: 0;

  li,
  ol,
  ul {
    @extend %list-unstyled;
  }
}

.list-unstyled {
  @extend %list-unstyled;
}