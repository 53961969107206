//
// Icons
// =============================================================================

@import "icons-glyphicons";
@import "icons-icomoon";

%icon {
  @include font-smoothing(on);
  color: inherit;
  display: inline-block;
  font-size: inherit;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: inherit;
  margin-right: $margin-xs;
  vertical-align: bottom;
  vertical-align: text-bottom;

  &:before {
    color: inherit;
    font-size: inherit;
    text-shadow: inherit;
  }

  &.x05 {
    font-size: $font-size-xl / 2;
  }

  &.x1 {
    font-size: $font-size-xl;
  }

  &.x2 {
    font-size: $font-size-xl * 2;
  }

  &.x3 {
    font-size: $font-size-xl * 3;
  }

  &.x4 {
    font-size: $font-size-xl * 4;
  }

  &.x5 {
    font-size: $font-size-xl * 5;
  }
}

.icon,
.glyphicons,
.icomoon {
  @extend %icon;

  .btn & {
    margin-right: rem($margin-sm);
  }
  .btn.btn-xs & {
    margin-right: $margin-xs / 2;
  }
}

.icon-circle {
  @include size($font-size-h6);
  background-color: $blue-dark;
  border-radius: $font-size-h6;
}