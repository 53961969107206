%flex {
  @include align-content(stretch);
  @include align-items(stretch);
  @include display(flex);
  @include flex-flow(row wrap);
  @include justify-content(space-around);
  &.flex-start {
    @include justify-content(flex-start);
  }
  &.flex-center {
    @include justify-content(center);
  }
}

%flex-item {
  @include flex(1);
  padding-right: $margin-base;
}

%auto {
  @include flex(1 auto);
}

%full {
  @include flex(1 100%);
  padding-right: 0;
}

%three-fourths {
  @include flex-utility(1, percentage(3/4));
}

%two-thirds {
  @include flex-utility(1, percentage(2/3));
  .third + & {
    padding-right: 0;
  }

  @media screen and (max-width: $break-sm) {
    @include flex-utility(1, 100%);
  }
}

%half {
  @include flex-utility(1, 50%);
   @include flex-utility(1, 50%);
   & + .half:nth-child(2n) {
    padding-right: 0;
   }

  @media screen and (max-width: $break-sm) {
    @include flex-utility(1, 100%);
    padding-right: 0;
  }
}

%third {
  @include flex-utility(1, 33.33%);
  & + .third:nth-child(3n + 2) {
    padding-right: 0;
  }

  @media screen and (max-width: $break-sm) {
    @include flex-utility(1, 100%);
    padding-right: 0;
  }
}

%fourth {
  @include flex-utility(1, percentage(1/4));

  @media screen and (max-width: $break-sm) {
    @include flex-utility(1, percentage(1/2));
  }

  @media screen and (max-width: $break-xs) {
    @include flex-utility(1, percentage(1));
  }
}

%fifth {
  @include flex-utility(1, percentage(1/5));
}

%sixth {
  @include flex-utility(1, percentage(1/6));
}

%seventh {
  @include flex-utility(1, percentage(1/7));

  @media screen and (max-width: $break-sm) {
    @include flex-utility(1, percentage(1/4));
  }

  @media screen and (max-width: $break-xs) {
    @include flex-utility(1, percentage(1/2));
  }
}

%eighth {
  @include flex-utility(1, percentage(1/8));
}

%ninth {
  @include flex-utility(1, percentage(1/9));
}

%tenth {
  @include flex-utility(1, percentage(1/10));
}

%eleventh {
  @include flex-utility(1, percentage(1/11));
}

%twelfth {
  @include flex-utility(1, percentage(1/12));
}


//
// Flex grid fledgling
// =============================================================================

.flex {
  @extend %flex;
}

.flex-item {
  @extend %flex-item;

  &.auto {
    @extend %auto;
  }

  &.full {
    @extend %full;
  }

  &.three-fourths {
    @extend %three-fourths;
  }

  &.two-thirds {
    @extend %two-thirds;
  }

  &.half {
    @extend %half;
  }

  &.third {
    @extend %third;
  }

  &.fourth {
    @extend %fourth;
  }

  &.fifth {
    @extend %fifth;
  }

  &.sixth {
    @extend %sixth;
  }

  &.seventh {
    @extend %seventh;
  }

  &.eighth {
    @extend %eighth;
  }
  
  &.ninth {
    @extend %ninth;
  }
  
  &.tenth {
    @extend %tenth;
  }
  
  &.eleventh {
    @extend %eleventh;
  }
  
  &.twelfth {
    @extend %twelfth;
  }
  
  @media screen and (max-width: $break-xs) {
    @include flex-utility(1, 100%);
    padding-right: 0 !important;
  }
}