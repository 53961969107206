#CalendarControl {
  background-color: $white;
  border: rem(1px) solid $dropdown-border-color;
  border-radius: 2px;
  box-shadow: $dropdown-box-shadow;
  display: none;
  margin-left: 1px;
  min-width: 260px;
  padding: $margin-sm;
  position: absolute;
  width: auto;
  a {
    color: $gray-medium;
    font-weight: $font-weight-bold;
    &:focus, &:hover {
      background-color: $gray-pale;
      border-radius: 2px;
      text-decoration: none;
    }
  }
  .calendar-controls {
    font-size: $font-size-md;
    width: 100%;
    .glyphicons {
      margin-right: 0;
    }
    .next {
      float: left;
      line-height: $line-height-sm;
      text-align: right;
      width: 25%;
      a {
        padding: 4px;
        text-align: center;
        vertical-align: middle;
        .glyphicons:first-of-type + .glyphicons{
          margin-left: -5px;
        }
      }
    }
    .previous {
      float: left;
      line-height: $line-height-sm;
      width: 25%;
      a {
        padding: 4px;
        text-align: center;
        vertical-align: middle;
        .glyphicons:first-of-type + .glyphicons{
          margin-left: -5px;
        }
      }
    }
    .title {
      float: left;
      font-weight: $font-weight-semibold;
      line-height: $line-height-base;
      text-align: center;
      width: 50%;
    }
  }
  .ResultsPagerStyle0 {
    font-size: $font-size-xs;
  }
  table {
    width: 100%;
    td {
      .current {
        background-color: $blue-dark;
        border-radius: 2px;
        color: $white;
      }
      .weekday,
      .weekend,
      .current {
        display: block;
        padding: ceil($margin-xxs / 2) $margin-xxs;
        text-align: center;
        &:active {
          background-color: $gray-bright;
          text-decoration: none;
        }
      }
    }
    th {
      text-align: center;    
      padding: $margin-xxs;
    }
    tr.hide-row {
      display: none;
    }
  }
}

#CalendarControlIFrame {
  display: none;
  position: absolute;
}