﻿
.tourtip {
    overflow: auto;

    img {
        float: left;
    }

    header {
        margin-left: 32px;
        float: left;
        margin-bottom: $margin-md;
    }

    .tourtip-header {
        font-size: 28px;
        margin-bottom: $margin-sm;
    }

    li {
        padding-bottom: $padding-sm;
    }

    .tourtip-buttons {
        margin-bottom: -12px;

        .button-dismiss {
            color: $blue-base;
            border: none;
            background: none;
            font-weight: $font-weight-normal;
            display: inline-block;
        }

        .button-accept {
            display: inline-block;
        }

        a {
            color: inherit;
        }
    }

    .glyphicons-remove {
        cursor: pointer;
    }
}

.tip {
    img {
        width: 50%;
        margin: 24px auto 24px auto;
        display: block;
    }
}