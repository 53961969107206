//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

//Taken from bootstrap and mildly altered

// Container that the modal scrolls within
.modal {
  bottom: 0;
  display: none;
  left: 0;
  overflow: hidden;
  outline: 0; // Prevent Chrome on Windows from adding a focus outline. For details, see https://github.com/twbs/bootstrap/pull/10951.
  position: fixed;
  right: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  z-index: $modal-z-index-backdrop;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include transform(translate(0, -25%));
    -webkit-transition: transform .3 ease-out;
    transition: transform .3s ease-out;
  }

  &.in .modal-dialog { @include transform(translate(0, 0)) }
}

// Modal background
.modal-backdrop {
  background-color: rgba(1, 1, 1, .60);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-z-index-backdrop;

  &.fade { 
    filter: alpha(opacity=0);
    opacity: 0;
  }

  &.in { 
    filter: alpha(opacity=50);
    opacity: .5;
  }
  
  > .modal {
    @include transform(translate(-50%, -50%));
    left: 50%;
    margin-bottom: 0;
    position: relative;
    top: 50%;
    z-index: $modal-z-index;
  }
}

.modal-body {
  margin: 32px #{-$margin-md} 0 #{-$margin-md};
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding: $margin-md;
}

// Actual modal
.modal-content {
  @extend .panel, .panel-default;
  background-clip: padding-box;
  background-color: $white;
  box-shadow: 0 3px 9px rgba(0,0,0,.5);
  outline: 0; // Remove focus outline from opened modal
  position: relative;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  margin: 10px;
  position: relative;
  width: auto;
}

.modal-footer {
  background: $gray-pale;
  border-top: 1px solid $gray-bright;
  margin: 0 #{-$margin-base} #{-$margin-base} #{-$margin-base};
  padding: $margin-sm;
}

.modal-header {
  background: $white;
  left: 0;
  margin-bottom: 0;
  padding: 18px;
  position: absolute;
  top: 0;
  width: 100%;
}

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  height: 50px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
  width: 50px;
}

// Scale up the modal
@media (min-width: $screen-sm-min) {
  .modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    margin: 30px auto;
    width: 600px;
  }

  // Modal sizes
  .modal-sm { width: 300px; }
}

@media (min-width: $screen-md-min) {
  .modal-lg { width: 900px; }
}