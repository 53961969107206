﻿// Variables used throughout our scss (some from newer versions of PUI)

$indigo-light-user: #eceef8 !default;


// From PUI 3.0.6
// see - https://progress.ngpvan.com/design/colors

//Grape
$grape0: #f8f0fc !default;
$grape1: #f3d9fa !default;
$grape2: #eebefa !default;
$grape3: #e599f7 !default;
$grape4: #da77f2 !default;
$grape5: #cc5de8 !default;
$grape6: #be4bdb !default;
$grape7: #ae3ec9 !default;
$grape8: #9c36b5 !default;
$grape9: #862e9c !default;

// Orange
$orange0: #fff4e6 !default;
$orange1: #ffe8cc !default;
$orange2: #ffd8a8 !default;
$orange3: #ffc078 !default;
$orange4: #ffa94d !default;
$orange5: #ff922b !default;
$orange6: #fd7e14 !default;
$orange7: #f76707 !default;
$orange8: #e8590c !default;
$orange9: #d9480f !default;

// Red
$red0: #fff5f5 !default;
$red1: #ffe3e3 !default;
$red2: #ffc9c9 !default;
$red3: #ffa8a8 !default;
$red4: #ff8787 !default;
$red5: #ff6b6b !default;
$red6: #fa5252 !default;
$red7: #f03e3e !default;
$red8: #e03131 !default;
$red9: #c92a2a !default;

//Green
$green0: #ebfbee !default;
$green1: #d3f9d8 !default;
$green2: #b2f2bb !default;
$green3: #8ce99a !default;
$green4: #69db7c !default;
$green5: #51cf66 !default;
$green6: #40c057 !default;
$green7: #37b24d !default;
$green8: #2f9e44 !default;
$green9: #2b8a3e !default;

// Lime
$lime9: #5c940d !default;
$lime8: #66a80f !default;
$lime7: #74b816 !default;
$lime6: #82c91e !default;
$lime5: #94d82d !default;
$lime4: #a9e34b !default;
$lime3: #c0eb75 !default;
$lime2: #d8f5a2 !default;
$lime1: #e9fac8 !default;
$lime0: #f4fce3 !default;

//PUI4 screen size breakpoints
@mixin pui4-screen-sm {
    @media screen and (max-width: 576px) {
        @content;
    }
}

@mixin pui4-screen-md {
    @media screen and (min-width: 577px) and (max-width: 767px) {
        @content;
    }
}

@mixin pui4-screen-lg {
    @media screen and (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}

@mixin pui4-screen-xl {
    @media screen and (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin pui4-screen-height-max {
    @media screen and (max-height: 700px) {
        @content;
    }
}