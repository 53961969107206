.feature {
  padding-top: $margin-sm * 1.5;
  text-align: center;
  .feature-cta {
    margin-top: $margin-sm * 1.5;
    button {
      background: $white;
      border-color: $white;
      margin: auto;
      &:focus,
      &:hover {
        background-color: transparent;
      }
      &:active {
        border-color: $white;
      }
    }
  }
  .feature-heading {
    color: $white;
    font-weight: $font-weight-normal;
    line-height: 1.5;
    margin: auto;
    max-width: 80%;
  }
  .feature-image {
    line-height: 0;
    img {
      max-width: 80%;
    }
  }
  .feature-logo {
    display: block;
    img {
      width: 275px;
    }
  }
  .feature-sub-heading {
    color: $white;
    margin: $margin-sm * 1.5 0 $margin-base * 1.5 0;
    a {
      color: $white;
      font-weight: $font-weight-bold;
    }
  }
}

.feature-box {
  border: 1px solid $gray-bright;
  border-radius: 4px;
  margin-bottom: $margin-base;
  max-height: 235px;
  min-height: 235px;
  padding: $margin-base * 1.5 $margin-base;
  overflow: hidden;
}