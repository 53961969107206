//
// Environment
// =============================================================================

$asset-path                  : '../assets/'                            !default;


//
// Color palette
// =============================================================================

$white                       : #ffffff                                 !default;
$black                       : #000000                                 !default;
$navy                        : #0080C7                                 !default;

$gray-base                   : #4D5B74                                 !default;
$gray-dark                   : #191F2B                                 !default;
$gray-medium                 : #394258                                 !default;
$gray-tinted                 : #707F9A                                 !default;
$gray-light                  : #B2C1DB                                 !default;
$gray-bright                 : #D9E3EF                                 !default;
$gray-pale                   : #F3F5F8                                 !default;

$blue-base                   : #1daeff                                 !default;
$blue-dark                   : #0d98e6                                 !default;
$blue-light                  : #addeff                                 !default;
$blue-pale                   : #eaf7ff                                 !default;

$green-base                  : #27bb66                                 !default;
$green-dark                  : #22a95c                                 !default;
$green-light                 : #bceacf                                 !default;
$green-pale                  : #f2fcf6                                 !default;

$red-base                    : #ff5257                                 !default;
$red-dark                    : #de4145                                 !default;
$red-light                   : #fed5db                                 !default;
$red-pale                    : #ffeff4                                 !default;

$orange-base                 : #f88d0f                                 !default;
$orange-dark                 : #eb7510                                 !default;
$orange-light                : #ffddb6                                 !default;
$orange-pale                 : #fff6eb                                 !default;

$pink-base                   : #f34b9d                                 !default;
$pink-dark                   : #e62a86                                 !default;
$pink-light                  : #ffcce5                                 !default;
$pink-pale                   : #fff1f8                                 !default;

$purple-base                 : #7e56b8                                 !default;
$purple-dark                 : #683fa4                                 !default;
$purple-light                : #d8c1f8                                 !default;
$purple-pale                 : #f5effd                                 !default;

$yellow-base                 : #ffc103                                 !default;
$yellow-dark                 : #ffaa21                                 !default;
$yellow-light                : #ffecb3                                 !default;
$yellow-pale                 : #fff8e1                                 !default;

$teal-base                   : #12c7a8                                 !default;
$teal-dark                   : #00a488                                 !default;
$teal-light                  : #94eede                                 !default;
$teal-pale                   : #dafff9                                 !default;

//
// User Swatches
// =============================================================================
$amber-user                  : #ffc107                                 !default;
$blue-user                   : #2196f3                                 !default;
$cyan-user                   : #00bcd4                                 !default;
$green-user                  : #4caf50                                 !default;
$green-light-user            : #8bc34a                                 !default;
$indigo-user                 : #3f51b5                                 !default;
$lime-user                   : #cddc39                                 !default;
$orange-user                 : #ff9800                                 !default;
$orange-dark-user            : #ff5722                                 !default;
$pink-user                   : #e91e63                                 !default;
$purple-user                 : #9c27b0                                 !default;
$purple-dark-user            : #673ab7                                 !default;
$teal-user                   : #009688                                 !default;


//
// Font families
// =============================================================================

$font-family-sans            : 'Lato', sans-serif                      !default;
$font-family-serif           : serif                                   !default;
$font-family-mono            : Menlo, 'Courier New', monospace         !default;

$font-family-base            : $font-family-sans                       !default;


//
// Font sizes
// =============================================================================

$font-size-xxl               : 32px                                    !default;
$font-size-xl                : 24px                                    !default;
$font-size-lg                : 18px                                    !default;
$font-size-mdlg              : 16px                                    !default;
$font-size-md                : 14px                                    !default;
$font-size-sm                : 12px                                    !default;
$font-size-xs                : 11px                                    !default;
$font-size-xxs               : 10px                                    !default;

$font-size-h1                : $font-size-xl                           !default;
$font-size-h2                : $font-size-lg                           !default;
$font-size-h3                : $font-size-mdlg                         !default;
$font-size-h4                : $font-size-h2                           !default;
$font-size-h5                : $font-size-h2                           !default;
$font-size-h6                : $font-size-h3                           !default;

$font-size-base              : $font-size-md                           !default;


//
// Font weights
// =============================================================================

$font-weight-light           : 300                                     !default;
$font-weight-normal          : 400                                     !default;
$font-weight-semibold        : 700                                     !default;
$font-weight-bold            : 700                                     !default;

$font-weight-base            : $font-weight-normal                     !default;


//
// Line heights
// =============================================================================

$line-height-px              : round(golden-ratio($font-size-base + 1, 1)) !default;

$line-height-xl              : 2                                       !default;
$line-height-lg              : 1.8                                     !default;
$line-height-md              : 1.6                                     !default;
$line-height-sm              : 1.4                                     !default;
$line-height-xs              : 1.2                                     !default;
$line-height-xxs             : 1                                       !default;

$line-height-base            : $line-height-md                         !default;


//
// Text colors
// =============================================================================

$text-color-dark             : $gray-dark                              !default;
$text-color-medium           : $gray-medium                            !default;
$text-color-light            : $white                                  !default;

$text-color-base             : $text-color-medium                      !default;


//
// Link colors
// =============================================================================

$link-color                  : $blue-dark                              !default;
$link-color-hover            : $blue-base                              !default;
$link-weight                 : $font-weight-normal                     !default;


//
// Misc typesetting
// =============================================================================

$paragraph-width             : $font-size-base * 36                    !default;


//
// Headings
// =============================================================================

$heading-color               : $text-color-dark                        !default;
$heading-font-family         : $font-family-base                       !default;
$heading-font-weight         : $font-weight-light                      !default;

$heading-1-font-family       : $heading-font-family                    !default;
$heading-1-font-size         : $font-size-h1                           !default;
$heading-1-font-weight       : $font-weight-normal					   !default;

$heading-2-font-family       : $heading-font-family                    !default;
$heading-2-font-size         : $font-size-h2                           !default;
$heading-2-font-weight       : $font-weight-normal                     !default;

$heading-3-font-family       : $heading-font-family                    !default;
$heading-3-font-size         : $font-size-h3                           !default;
$heading-3-font-weight       : $font-weight-semibold                   !default;

$heading-4-font-family       : $heading-font-family                    !default;
$heading-4-font-size         : $font-size-h4                           !default;
$heading-4-font-weight       : $heading-font-weight                    !default;

$heading-5-font-family       : $heading-font-family                    !default;
$heading-5-font-size         : $font-size-h5                           !default;
$heading-5-font-weight       : $font-weight-normal                     !default;

$heading-6-font-family       : $font-family-sans                       !default;
$heading-6-font-size         : $font-size-h6                           !default;
$heading-6-font-weight       : $font-weight-normal                     !default;


//
// Margins
// =============================================================================

$margin-xxl                  : $line-height-px * 4                     !default;
$margin-xl                   : $line-height-px * 3                     !default;
$margin-lg                   : $line-height-px * 2                     !default;
$margin-md                   : $line-height-px                         !default;
$margin-sm                   : round($line-height-px / 2)              !default;
$margin-xs                   : round($line-height-px / 3)              !default;
$margin-xxs                  : round($line-height-px / 4)              !default;

$margin-base                 : $margin-md                              !default;


//
// Viewport
// =============================================================================

$break-lg                    : 120rem                                  !default;
$break-md                    : 90rem                                   !default;
$break-sm                    : 64rem                                   !default;
$break-xs                    : 40rem                                   !default;

$screen-xl-min               : $break-lg + rem(1px)                    !default;
$screen-lg-min               : $break-md + rem(1px)                    !default;
$screen-md-min               : $break-sm + rem(1px)                    !default;
$screen-sm-min               : $break-xs + rem(1px)                    !default;


//
// Icons
// =============================================================================

$icon-path-glyphicons        : 'd170x0azrpb2m0.cloudfront.net/fonts/glyphicons-regular'              !default;
$icon-path-icomoon           : 'd170x0azrpb2m0.cloudfront.net/fonts/icomoon'                         !default;


//
// Base
// =============================================================================

$border-radius-base          : 2px                                     !default;

$loading-bar-height          : 6px                                     !default;

$app-padding-branding        : 134px                                   !default;
$app-padding-h               : $margin-base                            !default;
$app-padding-v               : $margin-base                            !default;

$app-width                   : 1348px                                  !default;

$modal-z-index-backdrop      : 10000                                   !default;
$modal-z-index               : $modal-z-index-backdrop + 1             !default;

//
// Button base
// =============================================================================

$btn-bg                      : transparent                             !default;
$btn-border-color            : transparent                             !default;
$btn-border-radius           : $border-radius-base                     !default;
$btn-border-style            : solid                                   !default;
$btn-border-width            : 1px                                     !default;
$btn-box-shadow              : none                                    !default;
$btn-fcolor                  : $text-color-base                        !default;
$btn-display                 : block                                   !default;
$btn-font-family             : $font-family-base                       !default;
$btn-font-size               : $font-size-base                         !default;
$btn-font-weight             : $font-weight-bold                       !default;
$btn-line-height             : $line-height-xxs                        !default;


//
// Button sizes
// =============================================================================

$btn-xs-font-size            : $font-size-sm                           !default;
$btn-xs-padding-v            : 4px                                     !default;
$btn-xs-padding-h            : 8px                                     !default;

$btn-sm-font-size            : $font-size-base                         !default;
$btn-sm-padding-v            : 6px                                     !default;
$btn-sm-padding-h            : 12px                                    !default;

$btn-md-font-size            : $font-size-base                         !default;
$btn-md-padding-v            : 10px                                    !default;
$btn-md-padding-h            : 14px                                    !default;

$btn-lg-font-size            : $font-size-mdlg                         !default;
$btn-lg-padding-v            : 12px                                    !default;
$btn-lg-padding-h            : 24px                                    !default;

$btn-xl-font-size            : $font-size-lg                           !default;
$btn-xl-padding-v            : 18px                                    !default;
$btn-xl-padding-h            : 36px                                    !default;


//
// Input base
// =============================================================================

$input-bg                    : $white                                  !default;
$input-border-color          : $gray-light                             !default;
$input-border-radius         : $border-radius-base                     !default;
$input-border-style          : solid                                   !default;
$input-border-width          : 1px                                     !default;
$input-border                : $input-border-width $input-border-style $input-border-color;
$input-box-shadow            : none                                    !default;
$input-color                 : $text-color-base                        !default;
$input-display               : block                                   !default;
$input-font-family           : $font-family-base                       !default;
$input-font-size             : $font-size-base                         !default;
$input-font-weight           : $font-weight-base                       !default;
$input-line-height           : $line-height-xs                         !default;

$input-label-color           : $gray-medium                            !default;
$input-label-size            : $font-size-sm                           !default;
$input-optional-color        : $gray-tinted                            !default;
$input-placeholder-color     : $gray-light                             !default;

$input-message-bg            : $gray-pale                              !default;
$input-message-size          : $font-size-xxs                          !default;
$input-message-line-height   : $line-height-sm                         !default;

$input-select-box-shadow     : 0 2px 0 0 $gray-bright                  !default;

$input-z-index               : 2                                       !default;


//
// Input sizes
// =============================================================================

$input-xs-font-size          : $font-size-sm                           !default;
$input-xs-padding-v          : 4px                                     !default;
$input-xs-padding-h          : 8px                                     !default;

$input-sm-font-size          : $font-size-base                         !default;
$input-sm-padding-v          : 6px                                     !default;
$input-sm-padding-h          : 12px                                    !default;

$input-md-font-size          : $font-size-base                         !default;
$input-md-padding-v          : 10px                                    !default;
$input-md-padding-h          : 14px                                    !default;

$input-lg-font-size          : $font-size-mdlg                         !default;
$input-lg-padding-v          : 12px                                    !default;
$input-lg-padding-h          : 24px                                    !default;

$input-xl-font-size          : $font-size-lg                           !default;
$input-xl-padding-v          : 18px                                    !default;
$input-xl-padding-h          : 36px                                    !default;


//
// Form styles
// =============================================================================

$form-bg                     : $white                                  !default;
$form-border-color           : $gray-light                             !default;
$form-border-style           : solid                                   !default;
$form-border-width           : 1px                                     !default;
$form-border                 : $form-border-width $form-border-style $form-border-color;
$form-border-radius          : $border-radius-base                     !default;
$form-padding                : $margin-base                            !default;
$form-steps-bg               : $gray-pale                              !default;


//
// Toggle switch
// =============================================================================

$toggle-switch-height        : 24px                                    !default;
$toggle-switch-circle        : 16px                                    !default;
$toggle-switch-padding       : 4px                                     !default;
$toggle-switch-width         : ($toggle-switch-circle * 2) + ($toggle-switch-padding * 4) !default;
$toggle-switch-lg-width		 : $toggle-switch-width + 20;

//
// Responsive Grid
// =============================================================================

$grid-columns                : 24;
$gutter                      : golden-ratio(1rem, 1);
$column                      : golden-ratio(1rem, 3);

$break-lg                    : 120rem !default;
$break-md                    : 90rem  !default;
$break-sm                    : 64rem  !default;
$break-xs                    : 40rem  !default;

$max-width                   : $break-xs;

$screen-xl-min               : $break-lg + rem(1px);
$screen-lg-min               : $break-md + rem(1px);
$screen-md-min               : $break-sm + rem(1px);
$screen-sm-min               : $break-xs + rem(1px);

//
// Dropdowns
// =============================================================================

$dropdown-bg                 : $white;
$dropdown-bg-hover           : $gray-pale;

$dropdown-border-color       : $gray-bright;
$dropdown-line-height        : $line-height-sm;
$dropdown-padding-h          : $margin-base !default;
$dropdown-padding-v          : 0 !default;

$dropdown-width              : ($font-size-base * $line-height-px) / 2px !default;
$dropdown-heading-color      : $gray-base !default;
$dropdown-heading-size       : $font-size-sm !default;

$dropdown-link-padding-h     : $margin-base !default;
$dropdown-link-padding-v     : $margin-xxs !default;

$dropdown-user-picture-size  : $line-height-px !default;

$dropdown-box-shadow         : 0 2px 0 0 $gray-pale                  !default;

//
// Tooltips
// =============================================================================

$tooltip-bg                  : $gray-medium;
$tooltip-z-index			 : 1070									!default;
$tooltip-arrow-width		 : 9px									!default;