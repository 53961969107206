//
// Background and text colors
// =============================================================================

@mixin color-variation($color-name, $color-base) {
  &.#{$color-name} {
    background-color: $color-base;
    border-color: $color-base;
    color: $white;
  }
}

@mixin color-quad($color-name, $color-base, $color-dark, $color-light, $color-pale) {

  .bg-#{$color-name},
  .#{$color-name}-base {
    background-color: $color-base;
    color: $text-color-light;
  }

  .text-#{$color-name} {
    color: $color-base !important;
  }

  .bg-#{$color-name}-dark,
  .#{$color-name}-dark {
    background-color: $color-dark;
    color: $text-color-light;
  }

  .text-#{$color-name}-dark {
    color: $color-dark !important;
  }

  .bg-#{$color-name}-light,
  .#{$color-name}-light {
    background-color: $color-light;
    color: $color-dark;
  }

  .text-#{$color-name}-light {
    color: $color-light !important;
  }

  .bg-#{$color-name}-pale,
  .#{$color-name}-pale {
    background-color: $color-pale;
    color: $color-dark;
    border-color: rgba($color-light, .2) !important;
  }

  .text-#{$color-name}-pale {
    color: $color-pale !important;
  }
}


@mixin color-alt($color-name, $color-medium, $color-tinted, $color-bright) {

  .bg-#{$color-name}-medium,
  .#{$color-name}-medium {
    background-color: $color-medium;
    color: $text-color-light;
  }

  .bg-#{$color-name}-tinted,
  .#{$color-name}-tinted {
    background-color: $color-tinted;
    color: $text-color-light;
  }

  .bg-#{$color-name}-bright,
  .#{$color-name}-bright {
    background-color: $color-bright;
    color: $text-color-base;
  }
}

@mixin color-text($color-name, $color) {
    .text-#{$color-name} {
        color: #{$color};
    }
}


//
// Flexbox Widths
// =============================================================================
@mixin flex-utility($flex-grow, $flex-width) {
  
  @include flex($flex-grow $flex-width);
  max-width: $flex-width;
}


//
// Toggle font smoothing
// =============================================================================

@mixin font-smoothing($value: on) {
  @if $value == on {
     -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
     -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}


//
// Triangle with direction variations (colorless)
// =============================================================================

@mixin nub-triangle($width: rem(4px), $class-prefix: 'nub') {
  @include size(0);
  border-top: $width solid;
  border-right: $width solid transparent;
  border-left: $width solid transparent;
  display: inline-block;
  margin-left: rem($margin-xxs);
  vertical-align: middle;

  &.#{$class-prefix}-left,
  &.#{$class-prefix}-right {
    border-bottom: $width solid transparent;
    border-top: $width solid transparent;
  }

  &.#{$class-prefix}-left {
    border-left: none;
    border-right: $width solid;
  }

  &.#{$class-prefix}-right {
    border-left: $width solid;
    border-right: none;
  }

  &.#{$class-prefix}-up {
    border-top: none;
    border-bottom: $width solid;
  }
}


//
// Button base, sizes, & styles
// =============================================================================

@mixin button-base($margin: true, $dropdown: true) {
  @include appearance(none);
  background-color: $btn-bg;
  border-color: $btn-border-color;
  border-radius: $btn-border-radius;
  border-style: $btn-border-style;
  border-width: $btn-border-width;
  box-shadow: $btn-box-shadow;
  display: $btn-display;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  line-height: $btn-line-height;
  outline: 0;
  position: relative;
  white-space: nowrap;

  @if $margin {
    margin-bottom: $margin-sm;
  }
  
  @if $dropdown {
    
    &.btn-dropdown {
      border: none;
      padding: ($margin-sm + $btn-border-width) ($margin-base + $btn-border-width);
      position: relative;

      &:after {
        display: inline-block;
        color: inherit;
        content: "\25BE";
        padding-left: $btn-md-padding-h;
      }

      &:focus {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .dropdown-menu {
          display: block;
          width: 100%;
          z-index: 1000;
        }
      }

      &.btn-full {
        text-align: left;
        &:after {
          float: right;
        }
      }

      &.btn-xs {

        &:after {
          padding-left: $btn-xs-padding-h;
        }
      }

      &.btn-sm {

        &:after {
          padding-left: $btn-sm-padding-h;
        }
      }

      &.btn-lg {

        &:after {
          padding-left: $btn-lg-padding-h;
        }
      }

      &.btn-xl {

        &:after {
          padding-left: $btn-xl-padding-h;
        }
      }
    }
  }
}


@mixin button-size($name, $font-size, $padding-y, $padding-x, $base-size: md, $responsive-block: true) {

  %btn-#{$name} {
    font-size: $font-size;
    line-height: $font-size + 2;
    padding: $padding-y $padding-x;
  }

  .btn-#{$name} {
    @extend %btn-#{$name};
  }

  @if $name == $base-size {
    %btn {
      @extend %btn-#{$name};
    }
  }
}


@mixin button-style($name, $bg, $bg-dark, $color: $text-color-light, $color-dark: $color, $border-color: transparent) {

  %btn-#{$name} {
    background-color: $bg;
    border-color: $border-color;
    color: $color;
    outline: 0;

    @if $bg != $white {
      &.btn-ghost {
        background-color: transparent;
        border-color: tint($bg, 20%);
        color: tint($bg, 20%);
        &:focus,
        &:hover,
        .hover {
          background-color: tint($bg, 20%);
          color: $white;
        }
        &:active,
        .active {
          border-color: $bg-dark;
          background-color: $bg-dark;
          color: $white;
        }
      }
    }

    &.btn-secondary {
      background: $white;
      border-color: $gray-light;
      color: $bg;

      &:focus,
      &:hover,
      &.hover {
        background-color: tint($gray-bright, 80%);
        color: $bg;
      }

      &:active, 
      &.active {
        background: $gray-bright;
        color: $bg-dark;
      }
    }

    &:focus,
    &:hover,
    &.hover {
      @if $bg != transparent and $bg != $white {
        background-color: tint($bg, 30%);
        color: tint($color, 20%);
      }
      @else if $bg == $white {
        background-color: tint($bg-dark, 80%);
      }
      @else if $border-color != transparent {
        background-color: rgba($bg-dark, .1);
        color: tint($color, 20%);
      }
      @else {
        color: tint($color, 20%);
      }
    }

    &:active,
    &.active {
      background-color: $bg-dark;
      color: $color-dark;
      
      @if $bg == transparent and $border-color == transparent {
        text-decoration: underline;
      }
    }

    &:focus {
      @if $bg == transparent and $border-color == transparent {
        text-decoration: underline;
      }
    }

    &:disabled,
    &.disabled {
      cursor: default;
      opacity: .3;
      pointer-events: none;
    }
  }

  .btn-#{$name} {
    @extend %btn-#{$name};
  }
}

//
//mixin for adding shimmering animation to loading states
// =============================================================================

@mixin loading-animation($animationName) {
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: $animationName;
  animation-timing-function: linear;
  background: $gray-pale;
  background: linear-gradient(to left,  $gray-pale 10%, #E7E9EB 50%, $gray-pale 90%);
  transform: translate3d(0, 0, 0);
  transition: all .4s ease-in-out;
}


//
// Input base, sizes, and styles (should be merged with button base)
// =============================================================================

@mixin input-base() {
  @include appearance(none);
  background: $input-bg;
  border-color: $input-border-color;
  border-radius: $input-border-radius;
  border-style: $input-border-style;
  border-width: $input-border-width;
  box-shadow: $input-box-shadow;
  display: $input-display;
  font-family: $input-font-family;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  outline: 0;
  max-width: 100%;
  position: relative;
  text-rendering: optimizeLegibility;
  width: 100%;
  z-index: $input-z-index;

  @include placeholder {
    color: $input-placeholder-color;
  }

  .message &,
  &.message {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:read-only:not(select):not(.select),
  &[readonly]:not(select):not(.select),
  &[readonly="true"]:not(select):not(.select), 
  &[readonly="readonly"]:not(select):not(.select) {
    background-color: transparent !important;
    border: none;
    padding: 0;
   }

  &:-moz-read-only:not(select):not(.select) { //everything breaks when I try to group this with the other read-only tags
    background-color: transparent !important;
    border: none;
    padding: 0;
  }
}

@mixin input-size($size, $font-size: $font-size-base, $padding-y: $padding-md-y, $padding-x: $padding-md-x, $class: 'input-element', $base-size: md) {

  %#{$class}-#{$size} {
    font-size: $font-size;
    height: ($padding-y * 2) + $font-size + 4;
    padding: $padding-y $padding-x;
  }

  textarea%#{$class}-#{$size}{
    height: auto;
  }

  .#{$class}-#{$size} {
    @extend %#{$class}-#{$size};
  }

  @if $size == $base-size {

    %#{$class} {
      @extend %#{$class}-#{$size};
    }
  }
}

@mixin input-state($state, $bg: $white, $border-color: $gray-light, $color: $gray-dark, $class: 'input-element', $pseudo: false) {
  $selectors: '.#{$class}.#{$state}';

  @if $pseudo {
    $selectors: '.#{$class}:#{$state}, .#{$class}.#{$state}';
  }

  @if $class == false and $pseudo == true {
    @error "input-state(): You must specify a class to create an unnested pseudo style.";
  }
  @else if $pseudo == true {
    #{$selectors} {
      background-color: $bg !important;
      border-color: $border-color !important;
      color: $color !important;
      &:focus,
      &:hover {
        border-color: $border-color !important;
      }
    }
  }
  @else {
    #{$selectors} {
      background-color: $bg;
      border-color: $border-color;
      color: $color;
      &:focus,
      &:hover {
        border-color: $border-color;
      }
    }
  }
}

//
// Responsive Grid Mixin
// =============================================================================

@mixin grid-setup($name, $columns) {
  .grid-#{$name} {

    @for $i from 1 through $columns {

      .col-#{$i} {
        @include span-columns($i of $columns);
      }

      .shift-#{$i} {
        @include shift-in-context($i of $columns);
      }

      .unshift-#{$i} {
        @include shift-in-context(-$i of $columns);
      }
    }

    @for $i from 1 through $columns {

      @media screen and (min-width: $screen-sm-min) {

        .col-sm-#{$i} {
          @include span-columns($i of $columns);
        }
      }
    }

    @for $i from 1 through $columns {

      @media screen and (min-width: $screen-md-min) {

        .col-md-#{$i} {
          @include span-columns($i of $columns);
        }
      }
    }

    @for $i from 1 through $columns {

      @media screen and (min-width: $screen-lg-min) {

        .col-lg-#{$i} {
          @include span-columns($i of $columns);
        }
      }
    }

    @for $i from 1 through $columns {

      @media screen and (min-width: $screen-xl-min) {

        .col-xl-#{$i} {
          @include span-columns($i of $columns);
        }
      }
    }
  }
}


//
// Message states (default, info, warn, error)
// =============================================================================

@mixin alert-states() {

}