.badge {
  background-color: $gray-bright;
  border-radius: rem($border-radius-base);
  font-size: rem($font-size-sm);
  font-weight: $font-weight-semibold;
  display: inline-block;
  line-height: $line-height-xxs;
  margin-left: rem($margin-xxs);
  margin-right: rem($margin-xxs);
  padding: rem($margin-xxs/2);

  &.badge-lg {
    min-width: $margin-base * 1.5;
    padding: rem($margin-xxs);
    text-align: center;
  }
  
  &.active {
    background-color: $gray-tinted;
    color: $white;
  }
  
  &.blue {
    background-color: $blue-base;
  }
  
  &.light {
    background-color: transparent;
    color: $blue-base;
  }
}