//
// Steps
// =============================================================================

.steps {
  @extend %list-unstyled;
  @extend .panel;
  @extend .panel-default;
  @include display(flex);
  counter-reset: step;
  padding: $margin-sm;
  @media screen and (max-width: 32rem) {
    overflow: auto;
  }
}

.step {
  @include flex(2 1 auto);
  color: $gray-light;
  min-height: 28px;
  overflow: hidden;
  position: relative;

  a {
    background: $white;
    color: $gray-light;
    cursor: default;
    display: block;
    float: left;
    margin: 0 $margin-sm 0 $margin-sm;
    outline: none;
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;

    &:before {
      border: 2px solid $gray-light;
      border-radius: 50%;
      color: $gray-light;
      content: counter(step);
      counter-increment: step;
      display: block;
      float: left;
      font-weight: bold;
      height: 28px;
      margin-right: $margin-sm;
      text-align: center;
      vertical-align: middle;
      width: 28px;
    }
    
    &:focus,
    &:hover {
      color: $gray-light;
    }
  }

  &.active a:before {
    background-color: $blue-base;
    border-color: $blue-dark;
    color: $white;
  }

  .can-skip & a,
  &.active a,
  &.complete a,
  &.previous a {
    color: $link-color;
    &:focus,
    & a:focus,
    &:hover,
    & a:hover {
      color: $link-color-hover;
    }
  }

  .can-skip &:not(.active) a,
  &.complete:not(.active) a,
  &.previous:not(.active) a {
    cursor: pointer;
    pointer-events: auto;
  }

  &.complete a:before {
    background-color: $green-base;
    border-color: $green-dark;
    color: $white;
    content: "\e207";
    font-family: "Glyphicons Regular";
    font-weight: normal;
  }

  &.invalid a:before {
    background-color: $red-base;
    border-color: $red-dark;
    color: $white;
    content: "!";
    font-weight: 900;
  }

  &:last-child {
    a {
      border-right: 0;
    }
  }
  
  &:last-of-type {
    @include flex(1 auto);
    margin-right: 0;
    a {
      margin-right: 0;
    }
  }

  &:not(:last-of-type):after {
    border-top: 1px solid $gray-light;
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }

  @media screen and (max-width: 32rem) {
    @include flex(1 100%);
    content: "";
    min-width: auto;
    overflow: initial;
    a {
      display: inline-block;
      margin: 0;
      padding-left: $margin-sm;
      text-indent: -9999em;
      &:before {
        float: left;
        text-indent: 0em;
      }
    }

    &:after {
      left: calc(28px + #{$margin-sm}*2); //indicator height + padding
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}


//
// Progress
// =============================================================================

progress {
  @include appearance(none);
  @include size(100% $loading-bar-height);
  background: $gray-bright; //ie
  border: 0;
  border-radius: $border-radius-base;
  color: $blue-base;
  margin: $margin-sm 0;
  
  &::-webkit-progress-bar {
    background: $gray-bright;
    border-radius: $border-radius-base;
  }
  
  &::-webkit-progress-value {
    background: $blue-base;
    border-radius: $border-radius-base;
  }
  
  &::-moz-progress-bar {
    background: $blue-base;
  }
  
  .loading-bar-success & {
    color: $green-base; //ie
    
    &::-webkit-progress-value {
      background: $green-base;
    }
  
    &::-moz-progress-bar {
      background: $green-base;
    }
  }
  
  .loading-bar-warning & {
    color: $red-base; //ie
    
    &::-webkit-progress-value {
      background: $red-base;
    }
  
    &::-moz-progress-bar {
      background: $red-base;
    }
  }
}

.loading-bar-wrapper {
  overflow: hidden;
  position: relative;
  
  i, 
  .loading-icon {
    float: right;
    
    &:before {
      @include size($margin-base);
      @include transform(translateY(-50%));
      border-radius: rem($margin-base * 2);
      color: $white;
      font-size: $font-size-xs;
      padding: $margin-xxs/2 $margin-xxs;
      position: absolute;
      right: 0;
      top: 50%;
    }
  }

  &.loading-bar-indeterminate {

    progress {
      color: $gray-bright; //ie

      &::-moz-progress-bar {
        background: none;
      }
    }

    > .loader {
      animation: indeterminate 2.1s cubic-bezier(.65,.815,.735,.395) infinite;    
      background: $blue-base;
      display: block;
      height: $loading-bar-height;
      position: absolute;
      top: $margin-sm;
      width: 50% !important;
    }
  }
  
  &.loading-bar-success {
    
    i:before, 
    .loading-icon:before {
      background: $green-base;
      content: "\E013"; // checkmark
    }
  }
  
  &.loading-bar-warning {
    
    i:before, 
    .loading-icon:before {
      background: $red-base;
      content: "\E101"; // warning sign
      margin-left: 2px; // centering offset because yuck
    }
  }
}

@include keyframes (indeterminate) {
  0% { 
    left: -35%;
    right: 100%;
  }

  100%, 60% {
    left: 100%;
    right: -90%;
  }
}