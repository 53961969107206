﻿
/* To Prevent clashing with Forms portion of App, all CSS should be scoped beneath these IDs*/
#PageWorkflowContainer, #pageDashboard {

    $basicPageGray: #868E96;

    .badge {
        margin: 0;
        padding-left: $margin-xs;
        padding-right: $margin-xs;

        &.badge-xl {
            width: $margin-base * 2;
            padding: $margin-xxs * 1.5 0;
            text-align: center;
        }
    }

    #ThemeInformation {
        .select2-disabled {
            display: none;
        }
    }

    .square {
        /*PUI does not include a gray color.. so here it is*/
        /* mixin from PUI _mixins*/
        @include color-variation('gray', $basicPageGray)
    }

    input.ng-invalid.suppress-error {
        border-color: #B2C1DB;
    }

    .dropdown-menu {
        li.basic:hover {
            .glyphicons {
                color: $basicPageGray;
            }
        }
    }
    
    .dropdown.action-menu {
        a.disabled {
            color: #D3D3D3 !important;
            opacity: 0.6;
        }
    }

    #published {
        .col-page-type, .page-type {
            padding-left: 15%;
        }
    }
    /* Tweak CSS for reusable dropdown */
    reusable-dropdown {
        ul.dropdown {
            margin-left: 10px;
        }

        li.input-unit {
            margin-bottom: 0 !important;
        }
    }

    .field-list-header {
        font-weight: 700;
        border-bottom: 1px solid #D9E3EF;
    }

    .field-type-col, .field-label-col, .field-displayed-col, .field-default-col {
        display: inline-block;
        vertical-align: top;
    }

    .field-list-row {
        margin-top: 10px;

        .field-type-col {
            /*This makes the text align vertically with the text within the text box (in label column)*/
            padding-top: 4px;
        }
    }
    /*Smaller spinner*/
    .loading-spinner-wrapper.small-spinner {
        min-height: 0 !important;

        .loading-spinner {
            height: 20px !important;
            width: 20px !important;
            margin-top: 10px !important;

            .loading-dot {
                top: -100%;

                &:before {
                    background-color: black !important;
                }
            }
        }
    }
    /*Stuff for Event List*/
    .eventlist-filter-fields, .eventlist-details-fields {
        @extend .grid-full;

        .field-list-row {
            @extend .row;
            padding-bottom: 5px;
            margin-top: 5px;
        }

        .field-list-header {
            @extend .row;
            padding-bottom: 0;
            margin-bottom: 10px;
        }

        .field-displayed-col {
            @extend .col-lg-1;
            @extend .col-md-2;
            @extend .col-sm-2;
            @extend .col-4;

            .switch-label {
                vertical-align: bottom;
            }
        }

        .field-type-col {
            @extend .col-md-4;
            @extend .col-sm-4;
            @extend .col-8;
        }

        .field-label-col {
            @extend .col-md-8;
            @extend .col-sm-8;
            @extend .col-8;
        }

        .field-default-col {
            @extend .col-md-8;
            @extend .col-sm-8;
            @extend .col-8;
        }

        .includeEventMapRow {
            margin-bottom: 20px;
            margin-top: 20px;
            padding-bottom: 0;
        }

        .input-calendar {
            max-width: 100% !important;

            input[type=text] {
                background: #ffffff;
                border: 1px #B2C1DB solid;
                padding: 10px 14px;
            }
        }
    }

    #EventListModule_SearchButtonText, #EventListModule_ResultsTitle, #EventListModule_SignUpLinkText {
        max-width: 400px;
    }
    /*Stuff for Legislator Lookup*/
    .officials-panel-header {
        border: 0;
        height: 60px;
    }

    .selected-officials-panel-header {
        border: 0;
    }

    .target-section {
        .panel-header {
            padding-bottom: 10px;
            margin-bottom: 10px;

            .panel-title {
                font-size: 14px;
                font-weight: 700;
            }

            .panel-nav a {
                font-size: 12px;
            }
        }
    }

    .leg-lookup-address-fields {
        .field-type-col {
            width: 150px;
        }

        .field-label-col {
            width: 280px;
        }
    }

    #LegislatorLookupModule_SubmitButtonText {
        max-width: 400px;
    }

    .target-section, .selected-officials-panel {
        margin-top: 25px;

        .target-list {
            list-style: none;
            padding-left: 0;
            border-right: 1px solid #dde4ed;
            margin-bottom: 0;
            margin-top: 0;

            .target-row {
                display: inline-block;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                position: relative;
                text-overflow: ellipsis;
            }
            /*Used in cart*/
            .target-row.target-disabled {
                opacity: 0.3;
            }

            input + label.checkbox-label {
                border: 2px solid transparent;
            }

            input:focus + label.checkbox-label {
                border-color: #1daeff;
            }

            input:not(:disabled) + label.checkbox-label {
                cursor: pointer;
            }

            &:last-child {
                border-right: 0;
            }

            .checkbox-label {
                width: 100%;
                height: 100%;
                padding: 6px 0px 0px 6px;
                border-radius: 2px;
            }

            .checkbox:checked + label {
                background-color: #eaf7ff;
            }

            .checkbox:checked:disabled + label {
                background-color: #f4f5f7;
                opacity: 0.6;
            }

            .checkbox-label:before {
                display: none;
            }

            .checkbox-label:after {
                display: none;
            }

            .user-name {
                float: left;
            }

            .user-stuff {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100% - 0.3vw);
                vertical-align: middle;
            }

            .user-details-wrapper {
                display: block;
                float: right;
                color: #7d889a;
                min-height: 28px;

                .user-details {
                    vertical-align: top;

                    .user-details-count {
                        vertical-align: top;
                    }

                    .spinner-parent {
                        position: relative;
                        right: -40%;
                    }
                }

                .selection-icon {
                    width: 30px;
                    vertical-align: bottom;
                    text-align: right;
                    display: inline-block;
                    color: #b2c1db;

                    .selection-icon .glyphicons {
                        vertical-align: bottom;
                        margin-bottom: -1px;
                    }

                    &.cart-icon {
                        cursor: pointer;
                    }
                }
            }

            input:not(:disabled) + label.checkbox-label:hover .user-details-wrapper .selection-icon, .selection-icon.cart-icon:hover {
                color: #7d889a;
            }
        }
    }


    .attendeecheckin-fields {
        .field-displayed-col {
            width: 60px;

            .switch-label {
                vertical-align: bottom;
            }
        }
    }

    #AttendeeCheckInModule_AttendeeSectionTitle, #AttendeeCheckInModule_AddSignupButtonText {
        max-width: 400px;
    }

    #UnsubscribeModule_SubmitButtonText {
        max-width: 400px;
    }
    // These were originally written for the Configure Advocacy step
    /* PUI Hacks for the multi-select ui-select controls*/
    .select2-container-multi {
        font-size: smaller;
    }

    .select2-container-multi .select2-choices .select2-search-field input {
        font-family: inherit;
    }

    .ui-select-container.ui-select-multiple {
        box-shadow: 0 2px 0 0 #d9e3ef;
        display: inline-block;
        margin-bottom: .4125rem;
        min-width: 200px;
    }

    .select2-container-multi .select2-choices {
        /*Main container*/
        background-image: none;
        border-color: #b2c1db;
        border-radius: 2px;
        box-shadow: none;
        padding: 5px;
    }

    .ui-select-container.ui-select-multiple.open {
        border-color: #0d98e6;
    }


    .ui-select-match-item {
        /* Selected item tag */
        background: none !important;
        border: 1px solid #b2c1db !important;
        border-radius: 2px !important;
        color: #191f2b !important;
        display: inline-block !important;
        line-height: 1.2 !important;
        margin-right: .125rem !important;
        margin-top: .1875rem !important;
        padding: 6px 18px 6px 6px !important;
        width: auto !important;
    }

    .ui-select-match-item .ui-select-match-close {
        margin-top: 2px;
    }

    .ui-select-match-item:hover {
        background: #ddf3ff;
        border-color: #b2c1db;
        color: #191f2b;
    }

    .select2-dropdown-open .select2-search-field {
        width: 100%;
    }

    .select2-search-field input.ui-select-search {
        width: 100% !important;
        font-size: 14px !important;
        padding: 4px 0px 4px 10px !important;
        color: #191F2B !important;
    }

    .select2-container-multi.select2-container-active .select2-choices {
        border-color: #1daeff;
        box-shadow: none;
    }

    .ui-select-choices.ui-select-choices-content {
        /* Dropdown */
        color: #191f2b;
        font-size: 14px;
    }

    .ui-select-multiple.open .ui-select-choices.ui-select-choices-content {
        border-color: #0d98e6;
    }

    .ui-select-choices .ui-select-choices-group {
    }

    .ui-select-choices .ui-select-choices-group:hover {
        background: #fff;
    }

    .ui-select-choices-group .ui-select-choices-row.active {
        pointer-events: initial;
    }

    .ui-select-choices-group .ui-select-choices-row:hover {
        background-color: #0d98e6;
        color: #fff;
    }

    .ui-select-choices-row-inner {
        padding: 3px 7px 4px;
    }

    .select2-container-multi:not(.open) .select2-search-field {
        width: 100%; // fix the clickable area when the dropdown is closed
        input {
            width: 100%;
        }
    }
    /* PUI Hack to clean up Toggle Button Groups*/
    .toggle-group .toggle-group-option {
        flex: 1 50%;
    }

    .toggle-group .toggle-group-option .btn-toggle {
        width: 100% !important;
        flex: initial !important;
    }

    .toggle-group .toggle-group-option .input-toggle:checked + .btn.btn-toggle:before {
        content: '' !important;
        margin-right: 0 !important;
    }

    .inline-option-button {
        color: $link-color;
    }

    .nav-button-label {
        width: 30%;
        float: left;
    }

    .nav-tab-subheader {
        color: $gray-tinted;
        font-size: $font-size-sm;
        clear: both;
    }

    .popover-label-trigger-text {
        padding-left: 12px;
        text-decoration: underline;
        text-decoration-style: dotted;
        color: $gray-tinted;
        font-size: $font-size-sm;
    }

    .popover {
        max-width: 500px;
    }

    .popover-content {
        text-align: center;
        max-width: 350px;
        min-height: 210px;
    }

    .popover-description {
        font-weight: 400;
        color: #394258;
        float: left;
        text-align: left;
    }

    .toggle-section-checkbox {
        margin-bottom: 5px !important;
    }

    .toggle-section-control {
        margin-left: 30px;
    }

    .margin-top-md {
        margin-top: 16px;
    }

    .margin-top-lg {
        margin-top: 24px;
    }

    .margin-bottom-md {
        margin-bottom: 16px;
    }

    .padding-top-bottom-lg {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .expand-fields-button {
        text-decoration: none;
    }

    .field-row {
        display: table-row;
        height: 42px;
        padding-bottom: 12px;
    }

    .field-element {
        float: left;
        min-width: 300px;
    }

    .field-label-element {
        float: left;
        min-width: 280px;
        margin-right: 20px;
    }

    .field-default-element {
        float: left;
        width: 300px;

        select {
            box-shadow: 0 2px 0 0 #D9E3EF;
            font-size: 14px;
            padding: 6px 12px;
            background: #ffffff;
            border-color: #B2C1DB;
            border-radius: 2px;
            border-style: solid;
            border-width: 1px;
            font-weight: 400;
            line-height: 1.2;
            outline: 0;
            z-index: 2;
        }
    }

    .field-explainer-text {
        clear: both;
        padding-left: 30px;
        color: #707F9A;
        padding-top: 0;
    }

    #manageEmails {
        margin-top: 12px;

        .input-unit {
            margin-bottom: 12px;
        }

        .toggle-section-checkbox {
            margin-bottom: 0 !important;
        }

        .radio-list {
            margin-top: 16px;
        }

        .email-signup {
            padding-left: 30px;
            padding-top: 12px;
        }
    }

    #manageEmailInterests {
        clear: both;
        padding-top: 16px;
    }

    .ssp-subheader {
        max-width: 600px;
    }

    .sms-legal-disclaimer {
        padding-left: 30px;
    }

    #paymentDateChangeNumberOfDays {
        margin-top: 5px;

        input {
            display: inline;
            width: 75px;
            text-align: center;
        }

        input:disabled {
            width: 24px;
        }
    }

    .payment-date-calculator {
        margin-bottom: 0;
        margin-top: 12px;
        width: 600px;
    }

    .designation-filter {
        width: 47%;
        display: inline-flex;
        margin-top: 25px;
    }

    .designation-filter-select {
        select {
            box-shadow: 0 2px 0 0 #D9E3EF;
            font-size: 14px;
            padding: 6px 12px;
            background: #ffffff;
            border-color: #B2C1DB;
            border-radius: 2px;
            border-style: solid;
            border-width: 1px;
            font-weight: 400;
            line-height: 1.2;
            outline: 0;
            z-index: 2;
            float: right;
        }

        .designation-required-validation-error {
            display: contents;
        }
    }

    .page-activist-code-hamburger {
        cursor: move;
        display: inline-block;
        vertical-align: top;
    }

    .page-activist-code-label {
        display: inline-block;
        position: relative;
        margin-right: 8px;
        width: 47%
    }

    .page-activist-code-select {
        display: inline-block;
        position: relative;
        margin-right: 8px;
        width: 47%;
        vertical-align: top;
    }

    .page-activist-code-remove {
        display: inline-block;
        position: relative;
        vertical-align: top;
    }

    .activist-code-dnd-row {
        margin-bottom: 8px;
        width: 100%;
    }

    .activist-code-dnd-no-drag {
        display: inline-block;
        width: 95%;
    }
}
