﻿tree-view {
    &.dropdown {
        float: none;
    }
}

.treeview-container {
    .treeview-dropdown {
        border: 1px solid $gray-light;
        border-top: none;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        display: none;
        max-height: 300px;
        max-width: 100%;
        padding: 0 $input-md-padding-h $input-md-padding-v $input-md-padding-h;
        overflow-y: scroll;
    }
    &.treeview-open {
        .treeview-dropdown {
            border-color: $blue-base;
            display: block;
            .k-state-disabled {
                color: $gray-dark;
                opacity: .5;
            }
            .k-state-hover {
                background-color: $blue-dark;
                background-image: none;
                border-radius: 2px !important;
                color: $white;
            }
            .k-state-selected {
                background-color: $blue-dark;
                background-image: none;
                border-radius: 2px !important;
                color: $white;
            }
        }
        .treeview-label {
            border-bottom: none;
            border-color: $blue-base;
            box-shadow: none;
            padding-bottom: $margin-xxs;
        }
    }
}

.treeview-container {
    &.treeview-fancy {
        position: relative;
        span.multiple {
            display: block;
            padding: 6px;
        }
        .selected-nodes {
            background-color: $white;
            border: 1px solid $gray-light;
            border-radius: 2px;
            min-height: 38px;
            min-width: 200px;
            overflow: auto;
            padding: 2px;
            padding-right: 30px;
            position: relative;
            &:after {
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 5px solid #9B9B9B;
                content: '';
                display: block;
                height: 0px;
                right: $margin-sm;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 0px;
            }
            &:hover {
                border-color: $blue-base;
            }
            > li {
                border: 1px solid $gray-light;
                border-radius: 2px;
                float: left;
                line-height: 1;
                max-width: 100%;
                overflow: hidden;
                padding: $margin-xs;
                padding-right: $margin-base;
                position: relative;
                text-overflow: ellipsis;
                & + li {
                    margin-left: 2px;
                }
                .unselect-node {
                    color: $gray-dark;
                    display: inline-block;
                    font-size: $font-size-sm;
                    line-height: 1.2;
                    position: absolute;
                    right: $margin-xs;
                    top: 50%;
                    transform: translateY(-50%);
                    &:focus,
                    &:hover {
                        color: $gray-medium;
                    }
                    .glyphicons {
                        margin-right: 0;
                    }
                }
            }
        }
        .treeview-dropdown {
            border-color: $gray-light;
            border-style: solid;
            border-width: 0 1px 1px 1px;
            background-color: $white;
            padding: $margin-sm $margin-sm * 1.5 $margin-sm * 1.5 $margin-sm * 1.5;
            position: absolute;
            width: 100%;
            overflow: hidden;
            z-index: 5;
            li {
                cursor: default;
            }
            .fancytree-container {
                border: none;
                outline: none;
                .fancytree-node {
                    max-width: 100%;
                    overflow: hidden;
                    &:hover {
                        .fancytree-title {
                            background: $blue-pale;
                            color: $gray-dark;
                        }
                    }
                    &.fancytree-active,
                    &.fancytree-selected {
                        .fancytree-title {
                            background: $blue-base;
                            border-radius: 2px;
                            color: $white;
                        }
                    }
                }
                .fancytree-title {
                    border: none;
                    font-family: "Lato", sans-serif;
                }
            }
            .treeview-filter {
                margin-bottom: $margin-xxs;
                width: 92% !important
            }
        }
    }
}

.treeview-inner-container {
    overflow: auto; 
    height: 200px;
}

.code-position-tooltip {
    float: right;
}
/* Merge dropdown treeview specific CSS */
.merge-dropdown .treeview-container {
    .dropdown-list {
        width: 400px;
    }

    .cannot-select {
        font-weight: 700 !important;
    }

    .selected-items-wrapper {
        font-size: $font-size-base;
        line-height: $line-height-xs;
        padding: $input-md-padding-v $input-md-padding-h;
    }
}