.user-row {
  background-color: $white;
  padding: $margin-sm;
  width: 100%;
  .user-info {
    color: $gray-tinted;
    float: right;
    font-size: $font-size-xs;
    line-height: $margin-base * 1.5;
  }
}

.user-icon-example {
  text-align: center;
  > div {
    float: left;
    margin-bottom: $margin-sm;
    width: percentage(1/13);
  }
  @media screen and(max-width: $break-sm) {
    > div {
      width: percentage(1/6);
    }
  }
  @media screen and(max-width: $break-xs) {
    > div {
      width: percentage(1/4);
    }
  }
}

.user-icon {
  border-radius: 50%;
  color: $white;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  height: $margin-base * 1.5;
  line-height: $margin-base * 1.5;
  position: relative;
  text-align: center;
  width: $margin-base * 1.5;
  & + .user-name {
    color: $gray-dark;
    margin-left: $margin-sm;
  }
  &.avatar {
    vertical-align: middle;
    &.type {
      border: 2px solid transparent;
      position: relative;
    }
    &.blue {
      border-color: $blue-base;
      .type-indicator {
        background-color: $blue-base;
      }
    }
    &.empty {
      border-color: $gray-pale;
      .type-indicator {
        background-color: $gray-pale;
      }
    }
    &.green {
      border-color: $green-base;
      .type-indicator {
        background-color: $green-base;
      }
    }
    &.red {
      border-color: $red-base;
      .type-indicator {
        background-color: $red-base;
      }
    }
    &.purple {
      border-color: $purple-base;
      .type-indicator {
        background-color: $purple-base;
      }
    }
    &.orange {
      border-color: $orange-base;
      .type-indicator {
        background-color: $orange-base;
      }
    }
    &.pink {
      border-color: $pink-base;
      .type-indicator {
        background-color: $pink-base;
      }
    }
    &.yellow {
      border-color: $yellow-base;
      .type-indicator {
        background-color: $yellow-base;
      }
    }
    &.teal {
      border-color: $teal-base;
      .type-indicator {
        background-color: $teal-base;
      }
    }
    img {
      border-radius: 50%;
      height: 100%;
      vertical-align: top;
      width: 100%;
    }
  }
  &:not(.avatar) {
    &.amber {
      background-color: $amber-user;
    }
    &.blue {
      background-color: $blue-user;
    }
    &.cyan {
      background-color: $cyan-user;
    }
    &.empty {
      background-color: $gray-pale;
    }
    &.green {
      background-color: $green-user;
    }
    &.green-light {
      background-color: $green-light-user;
    }
    &.indigo {
      background-color: $indigo-user;
    }
    &.lime {
      background-color: $lime-user;
    }
    &.orange {
      background-color: $orange-user;
    }
    &.orange-dark {
      background-color: $orange-dark-user;
    }
    &.pink {
      background-color: $pink-user;
    }
    &.purple {
      background-color: $purple-user;
    }
    &.purple-dark {
      background-color: $purple-dark-user;
    }
    &.teal {
      background-color: $teal-user;
    }
  }
  .notification {
    background-color: $red-base;
    border: 1px solid $white;
    border-radius: 50%;
    height: $margin-sm * 1.5;
    line-height: $margin-xs * 2;
    position: absolute;
    right: -4px;
    top: -4px;
    width: $margin-sm * 1.5;
    &.lg {
      font-size: $font-size-xxs;
    }
    &.xl {
      font-size: 9px;
    }
  }
  .type-indicator {
      background-color: $gray-tinted;
      border: 1px solid white;
      border-radius: 50%;
      bottom: -3px;
      content: ' ';
      display: block;
      position: absolute;
      right: -1px;
      width: 12px;
      height: 12px;
      &.amber {
        background-color: $amber-user !important;
      }
      &.blue {
        background-color: $blue-user !important;
      }
      &.cyan {
        background-color: $cyan-user !important;
      }
      &.empty {
        background-color: $gray-pale !important;
      }
      &.green {
        background-color: $green-user !important;
      }
      &.green-light {
        background-color: $green-light-user !important;
      }
      &.indigo {
        background-color: $indigo-user !important;
      }
      &.lime {
        background-color: $lime-user !important;
      }
      &.orange {
        background-color: $orange-user !important;
      }
      &.orange-dark {
        background-color: $orange-dark-user !important;
      }
      &.pink {
        background-color: $pink-user !important;
      }
      &.purple {
        background-color: $purple-user !important;
      }
      &.purple-dark {
        background-color: $purple-dark-user !important;
      }
      &.teal {
        background-color: $teal-user !important;
      }
    }
}

.warning-row {
  background-color: $red-pale;
  color: $red-base;
  font-size: $font-size-xs;
  padding: $margin-sm;
  > span {
    display: block;
  }
  .warning-flag {
    font-weight: $font-weight-bold;
  }
  .warning-info {
    color: tint($red-base, 45%);
    float: right;
  }
}