.circle {
  border-radius: 50%;
  height: 100px;
  margin: auto;
  margin-bottom: $margin-sm;
  position: relative;
  width: 100px;
  @include color-variation('blue', $blue-base);
  @include color-variation('orange', $orange-base);
  @include color-variation('pink', $pink-base);
  @include color-variation('purple', $purple-base);
  @include color-variation('red', $red-base);
  @include color-variation('teal', $teal-base);
  @include color-variation('yellow', $yellow-base);
  &.for-designers {
    background-image: url('//d170x0azrpb2m0.cloudfront.net/images/for-designers.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
  &.for-developers {
    background-image: url('//d170x0azrpb2m0.cloudfront.net/images/for-devs.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
  .glyphicons {
    color: $white;
    display: block;
    font-size: 48px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.indicator {
  @include size($margin-sm - 2);
  background-color: $gray-base;
  border-radius: 50%;
  display: inline-block;

  &.blue {
    background-color: $blue-base;
  }

  &.green {
    background-color: $green-base;
  }
}

.square {
  border-radius: 2px;
  height: 65px;
  margin: auto;
  margin-bottom: $margin-sm;
  position: relative;
  width: 65px;
  @include color-variation('blue', $blue-base);
  @include color-variation('green', $green-base);
  @include color-variation('orange', $orange-base);
  @include color-variation('pink', $pink-base);
  @include color-variation('purple', $purple-base);
  @include color-variation('red', $red-base);
  @include color-variation('teal', $teal-base);
  @include color-variation('yellow', $yellow-base);
  .glyphicons {
    color: $white;
    display: block;
    font-size: 32px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}