//
// Panels  (to do: clean me)
// =============================================================================

.panel {
  border: 1px solid transparent;
  border-radius: $border-radius-base;
  line-height: $line-height-px;
  margin-bottom: $margin-sm;
  padding: $margin-base;
  position: relative;
  
  &.modal {
    max-width: 60vw;
    
    @media screen and (max-width: $break-sm) {
      max-width: 94vw;
    }
  }
}

.panel-header {
  @include clearfix;
  line-height: $line-height-xs;
  margin-bottom: $margin-sm * 1.5;
  padding-bottom: $margin-base;
  position: relative;

  .icon-circle {
    @include transform(translateY(-200%) translateX(-50%) scale(4));
    left: 50%;
    position: absolute;
    top: 0;
  }
  &.responsive { 
    @include display(flex);
    flex-wrap: wrap;
    margin-top: -#{$margin-xxs};

    .panel-title {
      @include flex(1 auto);
      margin-top: $margin-xs;
    }
    .panel-nav {
      @include flex(0 auto);
      margin-bottom: 0;
      margin-top: $margin-xxs;
    }
  }
}

.panel-tabs {
  @include clearfix();
  margin: -($margin-base + 1);
  margin-bottom: $margin-base;
  position: relative;
  &:before {
    border-bottom: 1px solid $gray-bright;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .tab {
    background-color: $gray-pale;
    border: 1px solid $gray-pale;
    border-bottom: none;
    float: left;
    position: relative;
    text-align: center;
    width: 50%;
    z-index: 0;
    
    &.active {
      background-color: $white;
      border-color: $gray-bright;
      border-bottom-color: $white;
      z-index: 2;
      
      a {
        pointer-events: none;
        
        &:hover {
          cursor: default;
        }
        
        h6 {
          color: $gray-dark;
        }
      }
    }
    
    a {
      display: block;
      h6 {
        color: $link-color;
        
        &:focus,
        &:hover {
          color: $link-color-hover;
        }
      }
      
      &:focus {
        text-decoration: none;
      }
    }
    
    h6 {
      font-weight: $font-weight-normal;
      padding: $margin-xs $margin-base;
    }
  }

  &.functional {
    background-color: $gray-pale;
    display: flex;
    .tab {
      @include flex(1 auto);
      background-color: $gray-bright;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      max-width: 25%;
      &.active {
        background-color: $white;
        position: relative;
        &:focus,
        &:hover {
          > a {
            display: none;
          }
          & .tab-title {
            display: block;
          }
        }
        &.editing {
          > a {
            display: none;
          }
          .tab-title {
            display: block;
          }
        }
      }
      &.add {
        max-width: 65px;
        a,
        span {
          color: $gray-dark;
          display: block;
          width: 100%;
        }
        span {
          padding: $margin-sm * 1.5 0;
        }
      }
      a {
        pointer-events: all;
      }
      h6 {
        color: $gray-dark;
        line-height: 24px;
        max-width: 100%;
        font-size: 16px;
        overflow: hidden;
        padding: $margin-sm * 1.5;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:focus,
        &:hover {
          color: $gray-dark;
        }
      }
      & + .tab {
        margin-left: $margin-xxs;
      }
      &:nth-child(1n + 5) {
        display: none;
      }
      .tab-title {
        @include clearfix();
        display: none;
        padding: $margin-xs;
        input[type="text"] {
          font-size: 16px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
  
  @media screen and(max-width: $break-xs) {
    
    .tab {
      
      h6 {
        font-size: $font-size-base;
        padding: $margin-xs $margin-xs;
      }
    }
  }
}

.panel-title {
  float: left;
  font-family: $heading-6-font-family;
  font-size: $heading-6-font-size;
  font-weight: $heading-6-font-weight;
  line-height: inherit;
}

.panel-nav {
  float: right;
  line-height: inherit;
  margin-bottom: -$margin-base;
  position: relative;

  .btn {
    display: inline-block;
    margin-bottom: $margin-sm;
    margin-left: $margin-sm;
  }

  .dismiss {
    color: $gray-light;
    font-size: 200%;
    font-weight: $font-weight-semibold;
    line-height: 0;
    padding: $btn-sm-padding-h $btn-sm-padding-v $btn-md-padding-h $btn-sm-padding-v;
    @include transform(translateY(-25%));
    &:focus,
    &:hover {
      color: $gray-dark;
    }
    &:active {
      background: $gray-pale;
      color: $gray-dark;
      text-decoration: none;
    }
  }
}

.panel-content {
  color: inherit;
  font-size: inherit;
  line-height: inherit;

  &.inner {
    @include clearfix();
    padding-bottom: $margin-base;
    & + .panel-header.no-border {
      .panel-title-sub {
        border-top: 1px solid $gray-bright;
      }
    }
  }
}

.panel-footer {
  padding-top: $margin-base;
}

.panel-buttons {
  text-align: right;

  .btn {
    display: inline-block;
    margin-bottom: 0;
    margin-left: $margin-sm;
  }
  @media screen and (max-width: $break-xs) {

    .btn {
      margin-left: $margin-xxs;
    }
  }
}

.panel-default {
  background: $white;
  border-color: $gray-bright;
  .panel-header {
    border-bottom: 1px solid $gray-bright;
    padding-bottom: $margin-sm * 1.5;
    &.no-border {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: $margin-sm * 1.5;
    }    
    &.tour {
      padding-bottom: 0px;
    }
    .panel-title-sub {
      padding-top: $margin-sm * 1.5;
      &.no-padding {
        padding-top: 0;
      }
    }
  }
}

.panel-default {
  box-shadow: 0 2px 0 0 rgba($gray-bright, .5);
}

.panel-icon,
.panel-info {
  background: $white;
  border-color: $gray-bright;
  color: $gray-medium;
  font-size: $font-size-sm;
  line-height: $line-height-px;

  .panel-header {
    margin-bottom: 0;
  }
}

.panel-icon {
  margin-top: $margin-base;
  
  .panel-header {
    padding-top: $margin-sm;
    padding-bottom: $margin-sm;
  }
}