////

.checkbox-unit,
.radio-unit,
.switch-unit {
  @include clearfix;
  margin-bottom: $margin-sm;

  &:last-child {
    margin-bottom: 0;
  }
}

.checkbox,
.radio {
  left: -9999em;
  position: absolute;

  ~ label {
    cursor: pointer;
    float: left; // or line breaks will be dumb
    padding-left: $font-size-lg + $margin-sm;
    position: relative;

    &.label-left { //adding a label-left class until we get that sweet sweet preceding sibling selector in css4
      padding-left: 0;
      padding-right: $font-size-lg + $margin-sm;

      &:after {
        left: auto;
        right: 3px;
      }

      &:before {
        left: auto;
        right: 0;
      }
    }

    &:before {
      @include size($font-size-lg);
      background: transparent;
      border-radius: $border-radius-base;
      border: 1px solid $gray-light;
      color: inherit;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      top: 3px;
    }

    &:after {
      @include transition(all .05s ease-in-out);
      color: $white;
      content: "\E207";
      display: block;
      font-family: 'Glyphicons Regular';
      font-size: $font-size-sm;
      line-height: 1;
      left: 3px;
      position: absolute;
      top: 5px;
    }
  }

  &:not(:checked),
  &.unchecked {

    ~ label {

      &:after {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  &:checked,
  &.checked {

    ~ label {

      &:before {
        background-color: $white;
        border-color: $gray-light;
      }

      &:after {
        color: $blue-base;
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:focus,
  &:hover,
  &.hover {

    ~ label {

      &:before {
        border: 1px solid $blue-base;
      }
    }
    
    &:checked:not(:disabled),
    &.checked:not(:disabled){
      
      + label {
        
        &:before {
          border-color: $blue-dark;
        }
      }
    }
  }
  
  &:focus,
  &.focus {

    ~ label {

      &:before {
        border-color: $blue-base;
        box-shadow: 0 0 4px 0 $blue-base;
      }
    }
  }

  &:active,
  &.active {

    ~ label {

      &:before {
        background-color: $blue-dark;
        border-color: $blue-dark;
      }

      &:after {
        color: $white;
      }
    }
  }

  &[disabled],
  &.disabled,
  :disabled {

    ~ label {
      cursor: default;
      opacity: .6;
      &:before {
        background-color: $gray-pale;
        border-color: $gray-light;
      }

      &:after {
        color: $gray-light !important;
      }
    }
  }
}

.radio {

  + label {

    &:before {
      border-radius: $font-size-lg + 2;
    }

    &:after {
      @include size($font-size-lg - 8);
      background-color: $blue-base;
      border-radius: $font-size-lg - 8;
      content: "";
      left: 4px;
      top: 7px;
    }

    &.label-left {
      &:after {
        left: auto;
        right: 4px;
      }
    }
  }

  &:active,
  &.active {

    ~ label {

      &:after {
        background-color: $white;
      }
    }
  }

  &[disabled],
  &.disabled {

    ~ label {

      &:after {
        background-color: $gray-light;
      }
    }
  }
}

.switch-unit {
  @include clearfix;
  position: relative;
  vertical-align: middle;
}

.switch {
  @include size($toggle-switch-width $toggle-switch-height);
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;

  + label {
    @include size($toggle-switch-width $toggle-switch-height);
    @include transition(all 0.1s ease-in);
    background: $gray-light;
    border: 1px solid $gray-light;
    border-radius: $toggle-switch-height;
    cursor: pointer;
    display: inline-block;
    font-weight: $font-weight-bold;
    margin: -$toggle-switch-padding 0;
    position: relative;
    text-indent: -9999em;
    z-index: 1;

    &:focus,
    &:hover {
      border-color: $blue-base;

      &:after {
        background: $blue-base;
      }
    }

    &:after {
      @include transform(translateY(-50%));
      @include size($toggle-switch-circle);
      background: $white;
      border-radius: $toggle-switch-circle;
      color: $white;
      content: "Off";
      font-size: $font-size-xxs;
      left: $toggle-switch-padding;
      line-height: $toggle-switch-circle;
      text-indent: $toggle-switch-circle + $toggle-switch-padding;
      top: 50%;
      position: absolute;
    }
  }

  &.minimal,
  &.minimal:checked {

    + label {

      &:after {
        content: "";
      }
    }
  }

  &:checked,
  &.checked {

    + label {
      background: $blue-base;
      border-color: $blue-base;

      &:after {
        background: $white;
        color: $text-color-light;
        content: "On";
        left: auto;
        right: $toggle-switch-padding;
        text-indent: -($toggle-switch-circle + $toggle-switch-padding);
      }
    }

    &.status + label {
      background: $green-base;
      border-color: $green-base;

      &:after {
        content: "Active";
        text-indent: -35px;
      }
    }
  }

  &:focus,
  &.hover,
  &:hover {

    + label {
      background-color: tint($gray-light, 20%);
      border-color: tint($gray-light, 20%);

      &:after {
        background-color: $white;
        color: $white;
      }
    }

    &.status + label {
      background-color: tint($red-base, 20%);
      border-color: tint($red-base, 20%);
    }

    &:checked {

      + label {
        background-color: tint($blue-base, 20%);
        border-color: tint($blue-base, 20%);
      }

      &.status + label {
        background: tint($green-base, 20%);
        border-color: tint($green-base, 20%);
      }
    }
  }
  
  &:focus,
  &.focus {

    + label {
      box-shadow: 0 0 4px 0 $blue-base;
    }
  }

  &.active,
  &:active {

    + label {
      background-color: $gray-base;
      border-color: $gray-base;

      &:after {
        background-color: $white;
        color: $white;
      }
    }

    &:checked {

      + label {
        background-color: $blue-dark;
        border-color: $blue-dark;
      }

      &.status + label {
        background: $green-dark;
        border-color: $green-dark;
      }
    }
  }

  &.disabled,
  &:disabled {

    + label {
      background-color: $gray-pale !important;
      border-color: $gray-light !important;
      cursor: default;

      &:after {
        background-color: $gray-light !important;
        color: $gray-light !important;
      }
    }
  }

  &.status {
    @include size($toggle-switch-lg-width $toggle-switch-height);
    
    + label {
      @include size($toggle-switch-lg-width $toggle-switch-height);
      background: $red-base;
      border: 1px solid $red-base;

      &:after {
        content: "Inactive";
      }
    }
  }
}