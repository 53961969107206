.table-wrapper {
  max-width: 100%;
  overflow-x: auto;
  &.table-wrapper-stretched {
    max-width: none;
    margin: 0 (-$margin-base) (-$margin-sm) (-$margin-base);
    & + .pagination-container {
      margin-top: $margin-base;
    }
  }
}

.table-panel {
  @extend .panel;
  @extend .panel-default;
  padding: 0;
}

.table {
  background-color: $white;
  border: 0;
  border-radius: $border-radius-base;
  line-height: $line-height-base;
  max-width: 100%;
  width: 100%;
  
  td,
  th {
    line-height: inherit;
    padding: $btn-md-padding-v;
    
    &:first-child {
      padding-left: $margin-base;
    }
    
    &:last-child {
      padding-right: $margin-base;
    }
  }
  &.minimal {
    .tbody {
      
      td {
        white-space: nowrap;
      }
      
      tr {
        background-color: $white;
        &:hover {
          background-color: $blue-pale;
        }
      }
    }
  }
}

.thead {
  border-bottom: 2px solid rgba($gray-bright, .5);
  
  td,
  th {
    font-weight: $font-weight-semibold;
    text-align: left;
    white-space: nowrap;
    
    a {
      font-weight: inherit;
    }
  }
}

.tbody {
  
  td {
    white-space: nowrap;
  }
  
  tr {    
    border-bottom: 1px solid rgba($gray-bright, .5);
    &:hover {
      background-color: $blue-pale;
    }
  }

}

.tfoot {
  
  td {
    font-weight: $font-weight-semibold;
  }
  
  tr {
    background-color: $gray-bright;
  }
}