.loading-spinner-container {
  background: rgba(255, 255, 255, 0.6);
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
  &.loading {
    display: block;
  }
  .loading-spinner {
    height: 40px;
    left: 50%;
    position: relative;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    @-webkit-keyframes loading-spinner {
      0%, 24%, 100% { opacity: 0; }
      25% { opacity: 1; }
    }

    @keyframes loading-spinner {
      0%, 24%, 100% { opacity: 0; }
      25% { opacity: 1; } 
    }
    .loading-dot {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      &:before {
        background-color: $blue-base;
        border-radius: 100%;
        content: '';
        display: block;
        height: 22.5%;
        margin: 0 auto;
        width: 22.5%;
        -webkit-animation: loading-spinner 0.8s infinite linear;
        animation: loading-spinner 0.8s infinite linear;
      }
      &.dot-2 {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        &::before {
          -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
        }
      }
      &.dot-3 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        &::before {
          -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
        }
      }
      &.dot-4 {
        -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        transform: rotate(135deg);
        &::before {
          -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
        }
      }
      &.dot-5 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        &::before {
          -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; 
        }
      }
      &.dot-6 {
        -webkit-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        transform: rotate(225deg);
        &::before {
          -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; 
        }
      }
      &.dot-7 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
        &::before {
          -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; 
        }
      }
      &.dot-8 {
        -webkit-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
        transform: rotate(315deg);
        &::before {
          -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; 
        }
      }
    }
  }
}