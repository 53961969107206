//
// Background and text utilities for color shades
// =============================================================================

@include color-quad('blue',   $blue-base  , $blue-dark  , $blue-light  , $blue-pale  );
@include color-quad('green',  $green-base , $green-dark , $green-light , $green-pale );
@include color-quad('red',    $red-base   , $red-dark   , $red-light   , $red-pale   );
@include color-quad('gray',   $gray-base  , $gray-dark  , $gray-light  , $gray-pale  );

@include color-quad('purple', $purple-base, $purple-dark, $purple-light, $purple-pale);
@include color-quad('orange', $orange-base, $orange-dark, $orange-light, $orange-pale);
@include color-quad('pink',   $pink-base  , $pink-dark  , $pink-light  , $pink-pale  );
@include color-quad('yellow', $yellow-base, $yellow-dark, $yellow-light, $yellow-pale);
@include color-quad('teal',   $teal-base  , $teal-dark  , $teal-light  , $teal-pale  );


@include  color-alt('gray',   $gray-medium, $gray-tinted, $gray-bright);

@include color-text('white', $white);


//
// Color swatch styles
// =============================================================================

.colors {
  @extend %list-unstyled;
  margin-bottom: $margin-sm;
  max-width: 100%;

  .color {
    border: 1px solid transparent;
    line-height: $line-height-sm;
    padding: $margin-sm * 1.5;

    > * {
      display: block;

      &.color-name {
        font-weight: $font-weight-bold;
      }

      &.color-hex,
      &.color-var {
        font-size: $font-size-sm;
      }
    }
  }
}

//
// User swatch styles
// =============================================================================

.color-user {
  color: $white;
}

.color-amber-user {
  background-color: $amber-user;
}

.color-blue-user {
  background-color: $blue-user;
}

.color-cyan-user {
  background-color: $cyan-user;
}

.color-green-user {
  background-color: $green-user;
}

.color-green-light-user {
  background-color: $green-light-user;
}

.color-indigo-user {
  background-color: $indigo-user;
}

.color-lime-user {
  background-color: $lime-user;
}

.color-orange-user {
  background-color: $orange-user;
}

.color-orange-dark-user {
  background-color: $orange-dark-user;
}

.color-pink-user {
  background-color: $pink-user;
}

.color-purple-user {
  background-color: $purple-user;
}

.color-purple-dark-user {
  background-color: $purple-dark-user;
}

.color-teal-user {
  background-color: $teal-user;
}
