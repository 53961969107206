﻿.loading-spinner-container {
  background: rgba(255, 255, 255, 0.6);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}

.loading-spinner-wrapper {
    .loading-spinner-container {
        background: none;
    }
}