.well {
	@include clearfix();
	background-color: $gray-pale;
	border: 1px solid $gray-light;
	border-radius: 2px;
	padding: $margin-base;
	position: relative;
	&.well-blue {
		background-color: $blue-pale;
		border-color: $blue-light;
	}
	&.well-white {
		background-color: $white;
	}
}