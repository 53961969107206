//
// Text
// =============================================================================

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  @include transition(all .075s ease-in-out);
  color: $link-color;
  font-weight: $link-weight;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &:focus,
  &:hover,
  &:active {
    color: $link-color-hover;
    text-decoration: none;
  }

  &:focus {
    text-decoration: underline;
  }

  &[disabled],
  &.is-disabled {
    cursor: default;
    pointer-events: none;
  }
}

body {
  @include font-smoothing(on);
  color: $text-color-base;
  font-family: $font-family-base;
  font-size: rem($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}


code,
pre {
  background: rgba($blue-base, .06);
  border-radius: $border-radius-base;
  color: shade($blue-base, 15%);
  font-size: rem($font-size-sm);
  line-height: inherit;
  padding: $border-radius-base;
}

pre {
  padding: $margin-base;
}


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $heading-color;
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;

  small,
  .small {
    font-family: $font-family-base;
    font-size: 61.8%;
    line-height: $line-height-xxs;
    margin-left: rem($margin-sm);
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  font-weight: $heading-1-font-weight;
  line-height: $line-height-xl;
}


h2, .h2 {
  font-size: $font-size-h2;
  font-weight: $heading-2-font-weight;
  line-height: $line-height-xs;
  &.tall {
    line-height: $line-height-xl;
  }
}


h3, .h3 {
  font-size: $font-size-h3;
  font-weight: $heading-3-font-weight;
  line-height: $line-height-sm;
}


h4, .h4 {
  font-size: $font-size-h4;
  line-height: $line-height-sm;
}


h5, .h5 {
  font-size: $font-size-h5;
  line-height: $line-height-base;
}


h6, .h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-normal;
  &.condensed {
    line-height: $line-height-xs;
    margin-bottom: $margin-sm;
  }
}


p {
  color: inherit;
  font-size: inherit;
  line-height: $line-height-lg;
  margin: 0;
  padding: 0 0 rem($margin-sm);
  max-width: rem($paragraph-width);
  text-rendering: optimizeLegibility;

  + ol,
  + ul {
    margin-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &.tall {
    line-height: 2.75;
  }

  &.wide {
    max-width: none;
  }
}

.page-title {
  color: $gray-medium;
  font-size: $font-size-h1;
  font-weight: $font-weight-light;
  margin-bottom: $margin-sm;
}

.page-title-container {
  @include clearfix;

  &.has-status {
    margin-top: 15px;
  }

  > .page-title {
    float: left;
  }

  .page-title-group {
    float: left;

    .page-title {
      margin-bottom: 0;
    }

    .page-title-sub {
      color: $gray-tinted;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
    
    & + .page-title-status {
      margin: 22px 0 0 $margin-sm;
    }
  }

  .page-title-icon {
    float: left;
    margin-right: $margin-sm;

    .square {
      height: 50px;
      width: 50px;
      > .glyphicons {
        color: $gray-pale;
      }
    }

    .status {
      border: 3px solid $gray-pale;
      border-radius: 50%;
      font-family: "Glyphicons Regular";
      font-size: $font-size-md;
      height: 30px;
      position: absolute; 
      right: -10px;
      text-align: center;
      top: -10px;
      width: 30px;

      &:before {
        font-size: $font-size-lg;
        line-height: 1.3;
      }
    }

    .status-active {
      background: $green-base;

      &:before {
        content: "\E174";
      }
    }

    .status-inactive {
      background: $gray-tinted;
      
      &:before {
        content: "\E188";
      }
    }

    .status-paused {
      background: $yellow-base;

      &:before {
        content: "\E175";
      }
    }
  }

  .page-title-icon + .page-title {
    margin: $margin-xxs 0 $margin-xxs 0;
  }

  .page-title-status {
    border: 2px solid $gray-light;
    border-radius: 15px;
    float: left;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    line-height: 1;
    margin: $margin-sm 0 0 $margin-sm;
    padding: $margin-xxs $margin-sm;
    text-align: center;
    text-transform: uppercase;
  }

}


small,
.small {
  color: $gray-tinted;
  font-size: rem($font-size-xs);
  line-height: $line-height-xs;
}


//
// Text weight
// =============================================================================

.light {
  font-weight: $font-weight-light !important;
}

.normal {
  font-weight: $font-weight-normal !important;
}

.semibold {
  font-weight: $font-weight-semibold !important;
}

.bold {
  font-weight: $font-weight-semibold !important;
}


//
// Text style
// =============================================================================

.strikethrough {
  text-decoration: line-through !important;
}

.underline {
  text-decoration: underline !important;
}

.italics {
  font-style: italic;
}


//
// Text alignment
// =============================================================================

.justify {
  text-align: justify !important;
}

.center {
  text-align: center !important;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

//
// Text sizes
// =============================================================================

.text-xxs {
  font-size: $font-size-xxs !important;
}

.text-xs {
  font-size: $font-size-xs !important;
}

.text-sm {
  font-size: $font-size-sm !important;
}

.text-base {
  font-size: $font-size-base;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-xl {
  font-size: $font-size-xl;
}

.text-xxl {
  font-size: $font-size-xxl;
}

