﻿.hidden {
    visibility: hidden;
}

.custom-form-field-modal{
	overflow: unset;
    min-height: fit-content;
    max-height: none;
}

.xx-small-top-margin {
	margin-top: $margin-xs/2 !important;
}

.zero-top-margin{
	margin-top: 0px !important;
}

.sm-top-bottom-padding {
	padding: $input-sm-padding-v 0px;
}

.display-flex {
	display: flex;
}
.flex-one {
    flex: 1;
}

.display-inline{
    display:inline;
}

div[data-controller='CustomFormFieldQuestionTypeController:BaseComponentController']{
	label, input, .componentRadio {
		vertical-align: unset !important;
	}
}


.custom-form-field-response{
	padding-bottom: $input-sm-padding-h;
}

input.ng-pristine.ng-invalid.ng-untouched{
	border-color: $gray-light;
}

.readonly-section{
    pointer-events: none;
    opacity: 0.5;
}
 
.label-with-overflow {
	max-width: 95% !important;
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mapped-green {
    color: #40c057;
}

.small-left-padding{
	padding-left: $margin-xs !important;
}

.ui-select-xs .ui-select-container .select2-choice > .select2-chosen{
		margin-top: $margin-xs/4;
		line-height: 32px !important;
	> span{
		margin-top: $margin-xs/4;
		line-height: 32px !important;
	}
}


.component {
    @include clearfix();
}

.componentsEditor {
    > div {
        & + div {
            padding-top: $margin-sm;
        }
    }
    .component-row {
        padding-bottom: $margin-sm;
        label {
            vertical-align: top;
        }
    }
    .radio-list {
        > li {
            & + li {
                padding-top: $margin-sm;
            }
        }
    }
    .componentDefaultValue,
    .componentDisplayed,
    .componentFieldLabel,
    .componentInterest,
    .componentTitleEditor,
    .componentRequired,
    .componentHidden,
    .componentQuestionEditor,
    .componentTitleLabel {
        display: inline-block;
        position: relative;
        width: auto;
    }
    .componentDisplayed,
    .componentFieldLabel,
    .componentRequired,
    .componentHidden {
        vertical-align: top;
        span {
            vertical-align: top;
        }
    }
    .componentDisplayed {
        margin-top: $margin-xs;
    }

    .componentRequired {
        margin-top: $input-xs-padding-v;
        -moz-min-width: 30px;
        -ms-min-width: 30px;
        -o-min-width: 30px;
        -webkit-min-width: 30px;
        min-width: 30px;
    }
    .componentDefaultValue {
        padding: 0 $margin-base;
        top: 4px;
        vertical-align: top;
        white-space: nowrap;
        width: 200px;

        label,
        .input-element {
            display: inline-block;
        }
        label {
            vertical-align: top;
            span {
                margin-right: $margin-sm;
                vertical-align: top;
            }
            > textarea {
                width: auto;
            }
        }
        select {
            margin-top: $border-radius-base;
            width: auto;
        }
        // When the screen is narrow, indent the default value beneath the other component fields.
        @media screen and (max-width: 1250px) {
            margin-top: $margin-xs;
            padding-left: $margin-base * 2;
        }
    }
    .componentInterest {
        min-width: 250px;
        margin-bottom: $margin-sm;
        max-width: 250px;
    }
    .componentRadio {
        display: inline-block;
        min-width: 50px;
        text-align: center;
        vertical-align: top;
        .radio-label {
            float: none;
            padding-left: 20px;
        }
    }
    .componentRequired{
        margin-left: $margin-base;
    }
    .componentHidden {
        margin-left: $margin-sm;
        margin-right: $margin-base;
        vertical-align: text-top;
        label {
            .glyphicons {
                margin-right: 0;
            }
            .disabled {
                color: $gray-light;
            }
        }
        input {
            + label {
                padding-left: 0px;
                float: none;
                position: inherit;
                &:before, &:after {
                    content: none;
                }
            }
        }
    }
    .componentQuestionEditor{
        padding-right: 20px;
        vertical-align: top;
        padding-left: 10px;
    }
    .componentTicketEditor,
    .componentTitleEditor {
        min-width: 300px;
        padding-left: $margin-base;
        vertical-align: top;
        &.interests-label {
            padding-right: 24px;
        }
    }
    .componentTicketEditor {
        min-width: 225px
    }
    .componentTitleLabel {
        min-width: 300px;
        padding-left: $margin-base;
        line-height: 32px;
    }
    @media screen and (max-width: 1250px) {
        .componentTitleEditor,
        .componentTitleLabel {
            min-width: 225px;
        }
    }
    @media screen and (max-width: 1280px) {
        .componentTicketEditor {
            min-width: 150px;
        }
    }
}

.tooltip-center-text {
    text-align: center !important;
}

.componentDefaultValue.componentDatepicker {
    label {
        margin-right:12px;
        display:inline-block;
    }
    .input-calendar {
        display: inline-block;
        margin-right: 10px;
        min-width: 50px;
        max-width: 130px;
        line-height: 32px;

        .input-element {
            background: $white;
            border: 1px $gray-light solid;
            padding: $btn-md-padding-v $btn-md-padding-h;
        }
    }
}

.component-instruction {
    margin-bottom: $margin-sm;
}

.ContributionForm {
    .componentDatepicker {
        top: auto;

        .input-container {
            .input-element {
                background: $white;
                border: 1px $gray-light solid;
                padding: $btn-md-padding-v $btn-md-padding-h;
            }

        }

        .input-message {
            margin-left: 60px;
        }

        label {
            text-align: right;
        }

        label, .input-calendar {
            display: inline-block;
            margin-right: 10px;
            min-width: 50px;
            max-width: 130px;
            line-height: 32px;
        }
    }
    .input-list.horizontal {
        li {
            margin-bottom: 0;
            padding-top: 0;
        }
    }
    label, input, .componentRadio {
        vertical-align: top;
    }
}

select::-ms-expand {
    display: none;
}

#buttonText {
    max-width:400px;
    ~ .error {
        max-width: 400px;
    }
}

.sectionTitle {
    font-size: $font-size-h6;
    overflow: auto;
    .checkbox-label {
        clear: both;
    }
    .sectionSubHeader {
        clear: both;
        padding-top: $margin-xs;
    }
}

.sectionEditor {
    margin-top: $margin-sm;
}

.sectionHeader {
    color: $gray-dark;
    font-weight: $font-weight-base;
}

.sectionSubHeader,
.componentDescription {
    color: $gray-tinted;
    font-size: $font-size-base;
    padding-bottom: $margin-base;
    max-width: none;
}
.componentDescription {
    font-size: $font-size-sm;
}

.preview-title {
    display: block;
    font-size: $font-size-h6;
    margin-bottom: $margin-sm;
}

#sidebar-container {
    position: relative;
}

#sidebar {
    @media screen and (max-width: $break-sm) {
        display: none;
    }
}

.section-title {
    max-width: 400px;
}

.activeSectionTitle {
    color: $gray-light;
    float: right;
    font-size: $font-size-base;
    line-height: 28.5px;
    @media screen and (max-width: 1200px) {
        display: block;
        float: none;
        line-height: 1;
    }
}

.componentFieldLabel {
    min-width: 200px;
    padding: 5px 0px 0px 12px;

    @media screen and (max-width: 1250px) {
        min-width: 160px;
    }
}

.sectionHeaders {
    border-bottom: 1px solid $gray-light;
    margin-bottom: $margin-sm;

    .componentDisplayed,
    .componentFieldLabel,
    .componentTitleEditor,
    .componentTicketEditor,
    .componentRequired,
    .componentHidden,
    .componentDefaultValue {
        display: inline-block;
        font-weight: $font-weight-semibold;
        padding-bottom: 0;
        vertical-align: bottom;
    }

    .componentDefaultValue {
        top: auto;
    }

    .componentDisplayed {
        width: $toggle-switch-width;
    }
    
    .componentTitleEditor {
        min-width: 300px;

        @media screen and (max-width: 1250px) {
            min-width: 225px;
        }
    }
    .componentTicketEditor {
        min-width: 225px;
        @media screen and (max-width: 1280px) {
            min-width: 150px;
        }
    }
    .componentRequired {
        padding-left: 0;
    }

    .componentInterest {
        font-weight: $font-weight-semibold;
        margin-bottom: 0;
    }
}

.radio-list {
    &.as-buttons {
        .radio-unit {
            @include flex(1 100%);
            .btn {
                white-space: normal;
                word-break: break-all;
                &:before {
                    display: none;
                }
            }
        }
    }
}

.input-unit {
    @include clearfix();
}

// Indent subcomponents beneath their parent component.
.subcomponent {
    div[component-type=HtmlEditorComponent] {
        margin-left: $toggle-switch-width;
        padding-left: $margin-base;
        .htmlEditor.html-editor-small {
            & + .cke {
                margin-top: $margin-xs;
            }
        }
    }
}
.additionalEventInformation {

    .componentHidden {
         width: 0;
        margin: 0;
    }

    .componentDefaultValue {
        margin-left: 20px;
    }

    .grid-row {       

        .componentDefaultValue {

            input {
                margin-top: -5px;
            }

            select {
                margin-top: -7px;
            }

        }

    }
}

.ticket-description, .ticket-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
}

.grid-row {
    padding-top: 12px;
}

.grid-row.ng-hide {
    padding-top: 0 !important;
}


.grid-header {
    display: inline-block;
    font-weight: 700;
    padding-bottom: 0;
    vertical-align: bottom;
}
.grid-text {
    margin-top: 5px;
}


/*Drag and Drop stuff*/
.simple-dnd {
    /**
     * For the correct positioning of the placeholder element, the dnd-list and
     * it's children must have position: relative
     */
    ol[dnd-list], ol[dnd-list] > li {
        position: relative;
    }

    /**
     * The dnd-list should always have a min-height,
     * otherwise you can't drop to it once it's empty
     */
    ol[dnd-list] {
        min-height: 0px;
        padding-left: 0px;
        list-style-type: none;
    }

    /**
     * The dndDraggingSource class will be applied to
     * the source element of a drag operation. It makes
     * sense to hide it to give the user the feeling
     * that he's actually moving it.
     */
    ol[dnd-list] .dndDraggingSource {
        display: none;
    }

    /**
     * An element with .dndPlaceholder class will be
     * added to the dnd-list while the user is dragging
     * over it.
     */
    ol[dnd-list] .dndPlaceholder {
        display: block;
        background-color: $blue-pale;
        border: 1px dashed $blue-base;
        border-radius: $border-radius-base;
        margin-bottom: $margin-xs;
        min-height: 40px;
        width: 100%;
    }

    /**
     * The dnd-lists's child elements currently MUST have
     * position: relative. Otherwise we can not determine
     * whether the mouse pointer is in the upper or lower
     * half of the element we are dragging over. In other
     * browsers we can use event.offsetY for this.
     */
    ol[dnd-list] > li {
        /*display: block;*/
        margin-bottom: -1px;
    }

    // dnd styling for theme custom merge fields
    ol[dnd-list] > li[data-component-type-name="ThemeCustomMergeField"] {
        @include clearfix();
        margin-bottom: 24px;

        > .ticket-list-handle {
            float: left;
            padding-top: 1rem;
            vertical-align: top;
        }

        > .ticket-list-handle + dnd-nodrag {
            display: inline-block;
            float: left;
            width: calc(100% - 25px);
        }
    }
}

.amount-option-dnd {
    /**
     * For the correct positioning of the placeholder element, the dnd-list and
     * it's children must have position: relative
     */
    ol[dnd-list], ol[dnd-list] > li {
        position: relative;
    }
    /**
     * The dnd-list should always have a min-height,
     * otherwise you can't drop to it once it's empty
     */
    ol[dnd-list] {
        min-height: 0px;
        padding-left: 0px;
        list-style-type: none;
    }
    /**
     * The dndDraggingSource class will be applied to
     * the source element of a drag operation. It makes
     * sense to hide it to give the user the feeling
     * that he's actually moving it.
     */
    ol[dnd-list] .dndDraggingSource {
        display: none;
    }
    /**
     * An element with .dndPlaceholder class will be
     * added to the dnd-list while the user is dragging
     * over it.
     */
    ol[dnd-list] .dndPlaceholder {
        display: block;
        background-color: $blue-pale;
        border: 1px dashed $blue-base;
        border-radius: $border-radius-base;
        margin-top: 10px;
        margin-bottom: 1px;
        min-height: 40px;
        width: 100%;
    }
    /**
     * The dnd-lists's child elements currently MUST have
     * position: relative. Otherwise we can not determine
     * whether the mouse pointer is in the upper or lower
     * half of the element we are dragging over. In other
     * browsers we can use event.offsetY for this.
     */
    ol[dnd-list] > li {
        background-color: #fff;
        margin-bottom: -1px;
    }
}

.ecard-dnd {
    /**
     * For the correct positioning of the placeholder element, the dnd-list and
     * it's children must have position: relative
     */
    ol[dnd-list], ol[dnd-list] > li {
        position: relative;
    }

    /**
     * The dnd-list should always have a min-height,
     * otherwise you can't drop to it once it's empty
     */
    ol[dnd-list] {
        min-height: 0px;
        padding-left: 0px;
        list-style-type: none;
    }

    /**
     * The dndDraggingSource class will be applied to
     * the source element of a drag operation. It makes
     * sense to hide it to give the user the feeling
     * that he's actually moving it.
     */
    ol[dnd-list] .dndDraggingSource {
        display: none;
    }

    /**
     * An element with .dndPlaceholder class will be
     * added to the dnd-list while the user is dragging
     * over it.
     */
    ol[dnd-list] .dndPlaceholder {
        display: block;
        background-color: $blue-pale;
        border: 1px dashed $blue-base;
        border-radius: $border-radius-base;
        margin-bottom: $margin-xs;
        min-height: 74px;
        width:100%;
    }

    /**
     * The dnd-lists's child elements currently MUST have
     * position: relative. Otherwise we can not determine
     * whether the mouse pointer is in the upper or lower
     * half of the element we are dragging over. In other
     * browsers we can use event.offsetY for this.
     */
    ol[dnd-list] > li {
        margin-bottom: 10px;
    }
    
    .table-row {
        min-height:72px; /*Max height of thumbnails: see image-upload.css*/
    }
}

.premiums-dnd, .frequency-dnd {
    /**
     * For the correct positioning of the placeholder element, the dnd-list and
     * it's children must have position: relative
     */
    ol[dnd-list], ol[dnd-list] > li {
        position: relative;
    }

    /**
     * The dnd-list should always have a min-height,
     * otherwise you can't drop to it once it's empty
     */
    ol[dnd-list] {
        min-height: 0px;
        padding-left: 0px;
        list-style-type: none;
    }

    /**
     * The dndDraggingSource class will be applied to
     * the source element of a drag operation. It makes
     * sense to hide it to give the user the feeling
     * that he's actually moving it.
     */
    ol[dnd-list] .dndDraggingSource {
        display: none;
    }

    /**
     * An element with .dndPlaceholder class will be
     * added to the dnd-list while the user is dragging
     * over it.
     */
    ol[dnd-list] .dndPlaceholder {
        display: block;
        background-color: $blue-pale;
        border: 1px dashed $blue-base;
        border-radius: $border-radius-base;
        margin-bottom: $margin-xs;
        min-height: 40px;
        width:100%;
    }

    /**
     * The dnd-lists's child elements currently MUST have
     * position: relative. Otherwise we can not determine
     * whether the mouse pointer is in the upper or lower
     * half of the element we are dragging over. In other
     * browsers we can use event.offsetY for this.
     */
    ol[dnd-list] > li {
        margin-bottom: 10px;
    }
    
    .table-row {
        min-height:35px; /*Max height of thumbnails: see image-upload.css*/
    }
}


.customFieldSortListHandle {
    vertical-align: top;
    padding-top: 12px;
    cursor: move;
}

.ticket-list-handle {
    cursor: move;
    display: inline-block;
}


/*Styles used for generic tables and ecard image selection table*/
.table-headers {
    border-bottom: 1px solid $gray-light;
    margin-bottom: $margin-sm;

    .table-header {
        display: inline-block;
        font-weight: 700;
        padding-bottom: 0;
        vertical-align: bottom;
    }
}

hr.table-border {
    background:$gray-light;
    height:1px;
    border:0;
}

.table-list {
    list-style-type: none;
    padding-left: 0;

    .table-row {
        display: flex;
        width: 100%;
    }
}

.center-text {
    text-align: center;
}

.small-icon{
    font-size:0.85em;
    vertical-align:bottom;
}

.clickable {
    cursor:pointer;
}

.sectionTitle {
    .checkbox ~ label:before {
        background-color: #fff; /*Make checkboxes white within section titles*/
    }
}

[data-component-template="QuestionFieldSetComponent"] {
    li[data-component-template="QuestionComponent"], li[data-component-type-name="CustomField"], li[data-component-type-name="QuestionHtml"], li[data-component-type-name="CustomFormFieldQuestion"] {
        @include clearfix();
        margin-bottom: 1rem;

        > .ticket-list-handle {
            float: left;
            padding-top: 1rem;
            vertical-align: top;
        }

        > .ticket-list-handle + dnd-nodrag {
            display: inline-block;
            float: left;
            width: calc(100% - 25px);
        }

        .well {
            padding: $margin-sm $margin-base;

            .wide {
                vertical-align: -webkit-baseline-middle;
                vertical-align: middle;
            }

            .componentQuestionEditor + .componentDisplayed {
                margin-top: 4px;
            }

            .closeQuestion {
                .glyphicons-remove {
                    vertical-align: middle;
                    margin-right: 0;
                }
            }

            .questionResponseList {
                margin-top: 7px;
                margin-right: 30px;
            }


            .questionResponse {
                height: 100%;
                padding-bottom: 12px;

                > div:only-child {
                    margin-top: 6px;
                }

                > div:first-child {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    max-width:100%;
                }

                > div:nth-child(2) {
                    overflow: hidden;
                    font-size: smaller;
                    margin-top: -6px;
                    text-overflow: ellipsis;
                }

                > div > p:first-child {
                    display: inline-block;
                    color: #40c057;
                }

                > div > p:last-child {
                    margin-left: .5rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline;
                }
            }
        }

        .well:first-of-type {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .input-unit {
                margin-bottom: 0;
            }
        }

        .well + .well-white {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top-style: none;

            li > .ticket-list-handle {
                padding-top: 0.5rem;
                vertical-align: top;
            }
        }

        .overflow-auto {
            overflow: auto;
        }

        .gray-light {
            color: #adb5bd;
            background-color: white;
        }

        .pull-right {
            padding-top: 0;
        }

        .pull-top {
            padding-top: 0;
            padding-bottom: 1.5rem;
        }

        .editor-hide .question-html-editor {
            display: none;
        }

        .hint-expand {
            display: block;
            margin-top: 1em;
            margin-bottom: 1em;
        }

        .hint-collapse {
            display: block;
            margin-top: 1em;
            margin-bottom: 1em;

            &:before {
                content: "- ";
            }
        }

        .editor-hide .hint-collapse:before {
            content: "+ ";
        }

        .component-other {
            margin-top: 2em;

            .bold {
                font-weight: bold;
            }

            .margin-left {
                margin-left: 2em;
            }

            .input-element-base {
                display: inherit;
                width: inherit;
            }

            .checkbox-unit {
                display: inline-block;
                margin-bottom: 0;
                vertical-align: top;
                margin-top: .6em;
                margin-left: .5em;
            }
        }
    }

    ol + div > .btn:first-of-type {
        padding-left: 0;
    }

    .divider {
        background-color: $gray-bright;
        display: inline-block;
        height: 1rem;
        vertical-align: text-bottom;
        width: 2px;
    }

    .merge-dropdown {
        margin-bottom: 1.5em;
    }
}


.ui-select-sm {
    .ui-select-match {
        padding: 5px 14px !important;
        min-height: 38px !important;
    }
}

.ui-select-xs {
    .ui-select-match {
        padding: 0px 14px !important;
        min-height: 38px !important;

    }
}

.selected-frequency-parent .ui-select-xs .ui-select-match {
    padding-top: 4px !important;
}

.select2-hide-disabled-options {
    .select2-disabled {
        display: none !important;
    }
}

.premium-fmv {
    text-align:center; 
    padding-top: 9px;
}

.btn-group {
  > .btn {
    position: relative;
    float: left;
  }
  > .btn:first-child {
    margin-left: 0;
    &:not(:last-child):not(.dropdown-toggle) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  > .btn:last-child {
    &:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .btn + .btn {
    margin-left: -1px;
  }
}
.input-calendar {
  .btn-group {
    &.pull-left,
    &.pull-right {
      width: auto;
    }
    .btn {
      font-size: 12px;
    }
  }
}

.btn-default {
    @extend .btn-gray;
}

.selected-frequency-parent {
    .freq-option-dnd-handle {
        display: inline-block;
        vertical-align: middle;

        .customFieldSortListHandle {
            padding-top: 5px;
        }
    }
}

.select-amount-parent {
    .amount-option-dnd-handle {
        display: inline-block;
        vertical-align: middle;

        .customFieldSortListHandle {
            padding-top: 5px;
        }
    }

    .amount-option-remove {
        display: inline-block;

        .glyphicons-remove {
            margin-left: 10px;
            margin-bottom: -5px;
        }

        a[disabled] {
            opacity: .3;
        }
    }

    .amount-option {
        display: inline-block;
    }
}
/* Move headers for amount options so that it lines up */
.amountOptionsHeaders.sectionHeaders .componentDisplayed {
    width: 76px;
}

.sectionFormBuilder reusable-dropdown .input-unit {
  margin-left: 10px;
}

.freq-option-remove .glyphicons-remove {
    margin-left: 10px;
    margin-bottom: -5px;
}

#BuildTab {
  #Reusable_FormFooter, #Reusable_FormHeader {
    margin-left: 10px;
  }

  .emMarginLeftRight{
      margin:0 1em 0 1em;
  }

  .merge-dropdown{
      background-color:#fff;
  }
}

#CustomFormFieldSet {
  border: 0;
  margin: 0;
  padding: 0;
}



.EventSignupCheckboxParent, .EventSignupRadioParent {
    .default-badge .badge {
        margin-left: 0px;
    }     

    .customFieldSortListHandle {
        padding-top: 2px;
    }

    .componentDefaultValue {
        margin-top: -2px;
    }

    .table-list {
        li {
            margin-bottom: 8px !important;
        }
    }
}

.modal-body .event-type-row {
    margin-bottom: 10px;
}

.event-type-default-selection {
    display: inline-block;
}

[data-no-template="true"] {
    display: none;
}

[data-component-type-name="CoverCostsAmount"] {
    padding-top: 24px !important;

    .cover-costs-info {
        color: $gray-tinted;
    }
    .component-row .componentDefaultValue {
        margin-top: 0px !important;
    }

    .coverCostsDefaultContainer, .coverCostsConfigureContainer {
        display: inline-block;
        vertical-align: top;
        .small-icon {
            margin-left: 4px;
        }
    }
}

.inline-xs-link {
    font-size: $font-size-xs;
    margin-left: $margin-xs;
}

.subsection-description {
    color: $gray-tinted;
    font-size: $font-size-xs;
    margin-top: $margin-xs;
    margin-bottom: $margin-xs;
}

.subsection-label {
    margin-bottom: 30px;
}

.subsection-divider {
    border: none;
    height: $input-border-width;
    color: $input-border-color;
    background-color: $input-border-color;
    margin-top: -10px;
    margin-bottom: 10px;
}

.reduced-width {
    margin-left: 25px;
    margin-right: 25px;
}

.half-width-input-element {
    width: 50%;
}

.amt-opt-radio-list {
    margin-bottom: 12px !important;
}

.freq-option-remove {
    padding-top: 6px;

    a[disabled] {
        opacity: 0.5;
    }
}

.radio-list.other-amount {
    margin-bottom: 12px !important;
}

//fix selector widths when tip on build page
.col-md-18{
    .componentDefaultValue {
        select{
            width: 200px;
        }
    }
}

.sectionEditor {
    // "Individual or Organizations" selection info message + icon, within Contact Information section
    // also the info text displayed conditionally within employer and eligibility sections
    .org-or-indiv-message, .org-info {
        .glyphicons {
            display: inline-block;
            vertical-align: top;
        }

        small {
            display: inline-block;
            width: calc(100% - 24px);
            max-width: 600px;
        }
    }

    .org-or-indiv-message {
        margin-top: 8px;
    }
    .org-info {
        margin-bottom: 8px;
    }
}
.componentIconCol {
    width: 24px;
    display: inline-block;

    .glyphicons-building {
        margin-left: 9px;
        margin-top: 5px;
        vertical-align: top;
    }
}

.eft-disclaimer-label {
    .glyphicons-info-sign {
        margin-left: 4px;
        margin-right: 0px;
    }
}
.panel-badge {
    display:flex;
}

.new-badge {
    color: white;
    border-radius: 12px;
    background-color: #15aabf;
    padding: 3px 8px;
    border: none;
}

.designation-settings-table {
    margin-bottom: 14px;
}


// Overwrite progress UI _mixins.scss to make readonly text inputs look like readonly text inputs instead of like labels
span[title="EmployerOccupationIsRetiredCheckbox"] .input-element[type="text"]:disabled {
    background-color: #F3F5F8 !important;
    border-color: #B2C1DB !important;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    padding: 6px 12px !important;
}