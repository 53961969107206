.reusable-select2-input {
  .select2-highlighted {
    background-color: white !important;
    background: white;
  }
  .ui-select-choices-row:hover {
    background-color: #F3F5F8 !important;
    color: inherit !important;
  }
}

.reusable-item {
    max-width: 100%;
    position: relative;
    > {
        a:first-child, p:first-child {
            width: 55%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        a, p {
            display: inline-block;
            position: relative;
            margin-left: 5px;
            vertical-align: top;
            padding: 0;
        }

        a:only-child {
            width:initial;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.reusable-select2-input {
  &.direction-up > div {
    margin-top: 1px;
    position: absolute;
    top: 100% !important;
    background: #fff;
    box-shadow: none;
  }
  > div {
    width: 350px;
    border: 1px solid #B2C1DB !important;
    padding-top: 5px !important;
    box-shadow: 0 2px 0 0 #F3F5F8;
    z-index:500;
  }
}

.ui-select-container.reusable-select2-input.select2-container.select2-container-active {
    box-shadow: 0 2px 0 0 #D9E3EF;
    border-radius: 2px;
}

.ui-select-container.reusable-select2-input.select2-container.select2-container-active .select2-choice {
  background-color: white;
}

.input-select2.reusable-select2-input.ui-select-container .select2-choice:after {
  display: none;
}

.reusable-select2-input {
  > a {
    > span.select2-chosen {
      color: #444;
      font-weight: bold;
    }
    border-color: #c4cfdd !important;
    min-height: 30px !important;
  }
  .gray {
    color: #B2C1DB;
  }
  div.select2-search > input.select2-input {
    &:focus {
      background-color: white !important;
    }
    border-color: #B2C1DB !important;
    background-color: white !important;
  }
}

.reusable-fetch-error {
    margin-left: 7px;
}

.reusable-fetch-error > .error {
    position: absolute;
    top: -25px;
    width: 300%;
}


.align-with-reusable-dropdown{
    display:inline-block;
    vertical-align:top;
    float:left;
    margin-bottom:24px;
}

reusable-dropdown {
  li.input-unit {
     margin-bottom: 0 !important;
  }
}

.reusable-select2-input .select2-results .select2-result-label > span > a {
    cursor: pointer;
}


.reusable-select2-input .select2-results .select2-result-label {
    cursor: default !important;
}

// This is specifically targeting IE.  
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .reusable-item > p{
            color: #394258;
        }
}