//
// Tabs (to do: clean me)
// =============================================================================

.tabs {
  @extend %list-unstyled;
  margin-top: -$margin-base;

  > li {
    float: left;
  
    > a {
      border-color: transparent;
      border-style: solid;
      border-width: 2px 0 2px;
      display: block;
      line-height: inherit;
      margin-bottom: -2px;
      padding: $btn-xl-padding-v ($btn-md-padding-h / 2);
    }

    &.active {

      > a {
        color: $text-color-base;
        border-bottom-color: $link-color-hover;

        &:hover {
          cursor: default;
        }
      }
    }
  }
}