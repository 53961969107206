﻿
// Create new Form Type colors
ul.dropdown-menu {
    &.dropdown-menu-icons {

        > li {

            // hover state of form type icons
            &:hover {
                &.AdvocacyForm {
                    background-color: $purple-pale;                    
                    .type-icon {
                        color: $purple-base;
                    }                    
                }

                &.ContributionForm {
                    background-color: $green-pale;                    
                    .type-icon {
                        color: $green-base;
                    }                    
                }

                &.EventForm {
                    background-color: $orange-pale;                    
                    .type-icon {
                        color: $orange-base;
                    }                   
                }

                &.PetitionForm {
                    background-color: $blue-pale;                    
                    .type-icon {
                        color: $blue-dark;
                    }                    
                }

                &.SignupForm {
                    background-color: $pink-pale;                    
                    .type-icon {
                        color: $pink-base;
                    }                    
                }

                &.VolunteerForm {
                    background-color: $yellow-pale;                    
                    .type-icon {
                        color: $yellow-base;
                    }                    
                }

                &.PeerToPeerForm {
                    background-color: $indigo-light-user;
                    .type-icon {
                        color: $indigo-user;
                    }
                }

                &.EventHostForm {
                    background-color: $grape0;
                    .type-icon {
                        color: $grape6;
                    }
                }

                &.StoryCollectionForm {
                    background-color: $lime0;
                    .type-icon {
                        color: $lime6;
                    }
                }
            }
        }
    }
}

// Badge on dashboard (with the type name)
#dashboard .badge {
    color: $white;
    margin: 0;
    padding-left: $margin-xs;
    padding-right: $margin-xs;

    &.SignupForm {
        background-color: $pink-base;
    }

    &.AdvocacyForm {
        background-color: $purple-base;
    }

    &.PetitionForm {
        background-color: $blue-base;
    }

    &.ContributionForm {
        background-color: $green-base;
    }

    &.PeerToPeerForm {
        background-color: $indigo-user;
    }

    &.EventForm {
        background-color: $orange-base;
    }

    &.EventSignupForm {
        background-color: $orange8;
    }

    &.EventHostForm {
        background-color: $grape6;
    }

    &.VolunteerForm {
        background-color: $yellow-base;
    }

    &.SelfServicePortal {
        background-color: $teal-base;
    }

    &.EventHostForm {
        background-color: $grape6;
    }

    &.StoryCollectionForm {
        background-color: $lime6;
    }
}


// Icon at top of workflow steps
.page-title-icon .square {
    // default
    @extend .yellow;

    &.AdvocacyForm {
        @extend .purple;
    }

    &.ContributionForm {
        @extend .green;
    }

    &.EventSignupForm, &.EventForm {
        @extend .orange;
    }

    &.PetitionForm {
        @extend .blue;
    }

    &.SignupForm {
        @extend .pink;
    }

    &.SelfServicePortal {
        @extend .teal-base;
    }

    &.PeerToPeerForm {
        background-color: $indigo-user;
    }

    &.EventHostForm {
        background-color: $grape6;
    }

    &.StoryCollectionForm {
        background-color: $lime6;
    }
}


// Form Type Icon
.type-icon {

    @extend .glyphicons;
    
    &.AdvocacyForm {
        @extend .glyphicons-bullhorn;
    }

    &.ContributionForm {
        @extend .glyphicons-money;
    }

    &.EventForm {
        @extend .glyphicons-ticket;
    }

    &.EventSignupForm, &.EventHostForm {
        @extend .glyphicons-calendar;
    }

    &.PetitionForm {
        @extend .glyphicons-notes-2;
    }

    &.SignupForm {
        @extend .glyphicons-user-add;
    }

    &.SelfServicePortal {
        @extend .glyphicons-kiosk;
    }

    &.PeerToPeerForm {
        @extend .glyphicons-group;
    }

    &.VolunteerForm {
        @extend .icomoon;
        @extend .icomoon-smiley;
    }

    &.StoryCollectionForm {
        @extend .glyphicons-chat;
    }
}