a,
button {
  @include transition(all .075s ease-in-out);
}

.fade {
	opacity: 0;
	-webkit-transition: opacity .15s linear;
	transition: opacity .15s linear;
	&.in {
		opacity: 1;
	}
}