.map-marker {
  border-radius: 50%;
  border: 2px solid $white;
  height: 24px;
  width: 24px;
  &.blue {
    background-color: $blue-base;
  }
  &.red {
    background-color: $red-base;
  }
}

.map-popup {
  border-bottom: 2px solid $gray-bright;
  position: relative;
  min-width: 300px;
  max-width: 400px;
  &:before {
    bottom: -12px;
    content: ' ';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    z-index: 2;
    @include triangle(($margin-sm * 1.5 + 2), $gray-bright, down);
  }
  &:after {
    bottom: -9px;
    content: ' ';
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    z-index: 3;
    @include triangle(($margin-sm * 1.5), $white, down);
  }
  &.warning {
    &:after {
      @include triangle(($margin-sm * 1.5), $red-pale, down);
    }
  }
}