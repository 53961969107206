.tooltip {
  background-color: $tooltip-bg;
  border-radius: 2px;
  color: $white;
  display: block;
  font-size: $font-size-xs;
  max-width: 280px;
  opacity: 0;
  padding: $margin-sm ($margin-sm * 1.5);
  position: absolute;
  text-align: center !important; /* adding important tag so that left and right util classes don't override */
  white-space: normal;
  z-index: $tooltip-z-index;

  &.in     { opacity: .9; }
  &.bottom { margin-top:   $tooltip-arrow-width + 4px; }
  &.left   { margin-left: -$tooltip-arrow-width - 4px; }
  &.top    { margin-top:  -$tooltip-arrow-width - 4px; }
  &.right  { 
    margin-left:  $tooltip-arrow-width + 4px;
    min-width: 150px;
  }

  &.demo {
    display: inline-block;
    opacity: .9;
    position: relative;
    z-index: 0;
    & + .tooltip {
      margin-left: $margin-sm;
    }
    @media screen and(max-width: $break-sm) {
      display: block;
      & + .tooltip {
        margin-left: 0;
        margin-top: $margin-sm;
      }
    }
  }

  &.tooltip-stats {
    .tooltip-data {
      line-height: $line-height-xs;
    }
    .tooltip-text {
      line-height: $line-height-xs;
    }
  }

  .tooltip-stat-block {
    display: inline-block;
    vertical-align: bottom;
    & + .tooltip-stat-block {
      margin-left: ($margin-sm * 1.5);
    }
  }

  .tooltip-data {
    color: $white;
    display: block;
    font-size: $font-size-base;
    line-height: $line-height-xxs;
  }

  .tooltip-inner {
    line-height: $line-height-sm;
  }

  .tooltip-text {
    display: block;
    line-height: $line-height-lg;
  }
}

// Arrows
.tooltip-arrow {
  //hiding angular ui-bootstrap's arrow to make compatible with other types of tooltips
  display: none;
  height: 0;
  width: 0;
}

.tooltip {
  &:before, &.top:before {
    //defaults to top tooltip if no position is specified
    border-color: transparent;
    border-style: solid;
    border-top-color: $tooltip-bg;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    bottom: -$tooltip-arrow-width;
    content: "";
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    position: absolute;
  }
  &.right:before {
    border-right-color: $tooltip-bg;
    border-top-color: transparent;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    bottom: auto;
    left: -$tooltip-arrow-width;
    margin-left: 0;
    margin-top: -$tooltip-arrow-width;
    top: 50%;  
  }
  &.left:before {
    border-left-color: $tooltip-bg;
    border-top-color: transparent;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    bottom: auto;
    left: auto;
    margin-top: -$tooltip-arrow-width;
    right: -$tooltip-arrow-width;
    top: 50%;
  }
  &.bottom:before {
    border-bottom-color: $tooltip-bg;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    bottom: auto;
    left: 50%;
    margin-left: -$tooltip-arrow-width; 
    top: -$tooltip-arrow-width;
  }
}