$shadow-arrow-width: 11px;

.popover {
  background: $white;
  border: 1px solid $gray-bright;
  border-radius: $border-radius-base;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  color: $gray-dark;
  display: block;
  font-size: $font-size-sm;
  line-height: $line-height-xs;
  margin-bottom: $margin-sm;
  max-width: 360px;
  min-height: 100px;
  min-width: 300px;
  opacity: 0;
  outline: 0;
  padding: 18px;
  padding-bottom: 0;
  position: absolute;
  z-index: $tooltip-z-index;
  cursor: default;

  &.in     { opacity: 1; }
  &.right  { margin-left:  $tooltip-arrow-width; text-align: left !important;}
  &.bottom { margin-top:   $tooltip-arrow-width; }
  &.left   { margin-left: -$tooltip-arrow-width; }

  &.demo {
    opacity: 1;
    position: relative;
    z-index: 0;
  }

  .popover-inner {
    line-height: $line-height-sm;

    &.loading {
      .popover-title {
        .title-text {
          background: $gray-pale;
          height: $margin-md;
          width: 100px;
          @include loading-animation(titleText);
        }

        .user-icon {
          @include loading-animation(userIcon);
        }
      }

      .popover-info-list {
        li {
          background: $gray-pale;
          height: $margin-sm;
          width: 100%;
          @include loading-animation(infoList);
        }
      }
    }

    .popover-title {
      font-size: $font-size-lg;

      .title-text {
        display: inline-block;
        vertical-align: middle;
      }

      .user-icon {
        margin-right: $margin-sm;
        vertical-align: middle;

        .glyphicons {
          margin-right: 0;
          vertical-align: middle;
        }
      }
    }

    .popover-content {
      .popover-info-list {
        list-style: none;
        margin:  $margin-sm 0 $margin-md 0;
        padding-left: $margin-sm;
        li {
          margin-top: $margin-xxs;
          padding-left: 26px;
          text-indent: -13px;

          .glyphicons {
            color: $gray-light;
            font-size: $font-size-md;
            margin-right: $margin-sm;
            vertical-align: middle;
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .popover-section {
        border-top: 1px solid $gray-bright;
        padding: 18px 0;

        .section-title {
          font-size: $font-size-mdlg;
          font-weight: $font-weight-bold;
        }
        .section-data {
          font-size: $font-size-lg;
          margin-top: $margin-sm;
        }

        .popover-score {
          @include clearfix();

          .section-title {
            float: left;
          }

          .section-data {
            float: right;
            margin-top: 0;
          }
        }

        .popover-stats {
          @include display(flex);
          @include flex-flow(row);
          text-align: center;

          .popover-stat {
            @include flex(1 auto);
            .section-data,
            .section-data a {
              font-weight: $font-weight-bold;
              margin-top: 0;
            }
          }
        }

      }

      .panel-footer {
        @include clearfix();
        background: $gray-pale;
        border-top: 1px solid $gray-bright;
        margin: 0 -18px;
        padding: $margin-sm;

        .panel-buttons {
          @include display(flex);
          @include flex-flow(row);

          .btn {
            @include flex(1 auto);
            float: none;

            &:first-of-type {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
}

// Arrows
.tooltip-arrow {
  //hiding angular ui-bootstrap's arrow to make compatible with other types of tooltips
  display: none;
  height: 0;
  width: 0;
}

.popover {
  &:after, &.top:after {
    //defaults to top tooltip if no position is specified
    border-color: transparent;
    border-style: solid;
    border-top-color: $gray-pale;
    border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
    bottom: -$tooltip-arrow-width;
    content: "";
    left: 50%;
    margin-left: -$tooltip-arrow-width;
    position: absolute;
  }

  &:before, &.top:before {
    //defaults to top tooltip if no position is specified
    border-color: transparent;
    border-style: solid;
    border-top-color: $gray-bright;
    border-width: $shadow-arrow-width $shadow-arrow-width 0;
    bottom: -$shadow-arrow-width;
    content: "";
    left: 50%;
    margin-left: -$shadow-arrow-width;
    position: absolute;
  }

  &.right:after {
    border-right-color: $white;
    border-top-color: transparent;
    border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
    bottom: auto;
    left: -$tooltip-arrow-width;
    margin-left: 0;
    margin-top: -$tooltip-arrow-width;
    top: 50%;  
  }

  &.right:before {
    border-right-color: $gray-bright;
    border-top-color: transparent;
    border-width: $shadow-arrow-width $shadow-arrow-width $shadow-arrow-width 0;
    bottom: auto;
    left: -$shadow-arrow-width;
    margin-left: 0;
    margin-top: -$shadow-arrow-width;
    top: 50%; 
  }

  &.left:after {
    border-left-color: $white;
    border-top-color: transparent;
    border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
    bottom: auto;
    left: auto;
    margin-top: -$tooltip-arrow-width;
    right: -$tooltip-arrow-width;
    top: 50%;
  }

  &.left:before {
    border-left-color: $gray-bright;
    border-top-color: transparent;
    border-width: $shadow-arrow-width 0 $shadow-arrow-width $shadow-arrow-width;
    bottom: auto;
    left: auto;
    margin-top: -$shadow-arrow-width;
    right: -$shadow-arrow-width;
    top: 50%;
  }

  &.bottom:after {
    border-bottom-color: $white;
    border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
    bottom: auto;
    left: 50%;
    margin-left: -$tooltip-arrow-width; 
    top: -$tooltip-arrow-width;
  }
  &.bottom:before {
    border-bottom-color: $gray-bright;
    border-width: 0 $shadow-arrow-width $shadow-arrow-width;
    bottom: auto;
    left: 50%;
    margin-left: -$shadow-arrow-width; 
    top: -$shadow-arrow-width;
  }
}

@keyframes userIcon {
  0% { background-position: -36px 0 };
  100% { background-position: 0px 0 };
}

@keyframes infoList {
  0% { background-position: -300px 0 };
  100% { background-position: 0px 0 };
}

@keyframes titleText {
  0% { background-position: -100px 0 };
  100% { background-position: 0px 0 };
}