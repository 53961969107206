.input-calendar {
    max-width: 260px;
    &.range {
        max-width: 290px;

        > span {
            left: 18px;
            right: auto;
        }

        > input {
            min-width: 290px;
            padding-left: $margin-lg;
        }

        &.dropdown.open .dropdown-toggle{
            background: $blue-pale;
            border-color: $blue-base;
        }

        ul{
            width: 750px;

            .button-panel {
                float: right;
                margin-bottom: 38px;
                padding: $margin-xs;
                width: 27%;

                .action-buttons {
                    bottom: 0;
                    margin-bottom: $margin-xs;
                    position: absolute;
                    right: 0;

                    .btn {
                        display: inline-block;
                        margin-bottom: 0;

                        &:first-child {
                            margin-right: $margin-xs;
                        }
                    }
                }

                .btn{
                    margin-bottom: $margin-xxs;

                    .glyphicons {
                        display: none;
                    }

                    &.btn-blue .glyphicons {
                        display: inline-block;
                    }
                }
            }

            .calendar-unit {
                float: left;
                margin: 0 auto;
                padding: $margin-xs;
            }

            div.range-control {
                max-height: 400px;
            }

        }
    }

    .btn.btn-success {
        display: none;
    }

    .btn-group {
        float: none;
        width: 100%;
        .btn {
            display: inline-block;

            &.btn-danger {
                display: none;
            }

            &.btn-info {
                @extend .btn-gray;
                width: 100%;
            }
        }
    }

    em {
        color: $gray-medium;
        font-size: $font-size-sm;
        padding: $margin-xs;
    }

    .glyphicon {
        @extend .glyphicons;
        margin-right: 0 !important;

        &.glyphicon-chevron-left {
            @extend .glyphicons-chevron-left;
        }

        &.glyphicon-chevron-right {
            @extend .glyphicons-chevron-right;
        }
    }

    .pull-left {
        float: left;
    }

    .pull-right {
        float: right;
    }

    table {
        table-layout: fixed;

        &:focus {
            outline: none;
        }

        .btn {
            &.active {
                pointer-events: all;
            }

            &.btn-default {
                border: none;
                margin-bottom: 0;

                &:active {
                    background-color: $gray-bright;
                }

                &.active,
                &:focus,
                &:hover {
                    background-color: $gray-pale;
                }

                &.btn-info {
                    background-color: $blue-base;
                    color: $white;
                    .text-info {
                        color: $white;
                    }
                }

                &.btn-sm {
                    margin-bottom: 0;
                    padding: $margin-xxs $margin-xs;
                }

                .text-muted {
                    color: $gray-light;
                }

                .text-info {
                    /* Today's Date */
                    color: $blue-dark;
                    font-size: $font-size-md;
                    padding-top: 0;
                }
            }

            &[disabled="disabled"] {
                cursor: not-allowed;
                pointer-events: all;
            }
            
        }

        tbody {
            .btn {
                margin-bottom: 0;
                padding: $margin-xxs $margin-xs;
            }
        }

        thead {
            tr {
                th {
                    padding-bottom: $margin-xs;
                    text-align: center;

                    small {
                        color: $gray-dark;
                    }
                }
            }
        }
    }

    ul.dropdown-menu {
        border-radius: 2px;
        min-width: 260px;
        padding: $margin-sm;

        li {
            @include clearfix();
            padding: 0;

            &:focus,
            &:hover {
                background-color: inherit;
            }

            & + li {
                padding: $margin-xxs 0 !important;
            }
        }
    }
}

.input-time {
    .btn {
        margin-bottom: 0;
    }

    .btn-default {
        border: 1px solid $gray-light;
        font-weight: normal;
    }

    .btn-link {
        padding: $margin-xxs $margin-sm;
    }

    .form-control {
        @extend .input-element;
    }

    .glyphicon {
        @extend .glyphicons;
        color: $gray-dark;
        margin-right: 0 !important;

        &.glyphicon-chevron-up {
            @extend .glyphicons-chevron-up;
        }

        &.glyphicon-chevron-down {
            @extend .glyphicons-chevron-down;
        }
    }

    input {
        margin: 0 3px;
    }
}

.input-unit-calendar {
    padding-bottom: 25px;
    
    &.indented {
        padding-left: 30px;
    }
}