/*
 * Some variables declared by VAN, but not present in ProgressUI.
 * Since the styles below were imported from VAN, declare them here
 */
$padding-lg: $margin-base !default;
$padding-md: $margin-md !default;
$padding-base: $margin-sm !default;
$padding-sm: $margin-xs !default;
$padding-xs: $margin-xxs !default;


.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background: rgba(1, 1, 1, 0.3) !important;
  /* needs to be higher than z-index for app-header */
  z-index: 1001;
}

.ReactModal__Content.ReactModal__Content--after-open {
  background: transparent !important;
  border: 0 !important;
  margin: auto;
  max-width: 550px;
  padding: 0 !important;
  overflow: initial !important;
  width: calc(100% - 80px);

  .dismiss {
      font-size: 1rem;
  }

  @media screen and (max-width: $break-sm) {
      bottom: 0 !important;
      height: 100vh;
      left: 0 !important;
      right: 0 !important;
      top: 0 !important;
      width: 100vw;

      .modal-content {
          display: flex;
          flex-flow: column nowrap;
          height: 100vh;
          justify-content: space-between;
          padding: 0;
      }

      .modal-header {
          position: static;
      }

      .modal-body {
          flex-grow: 2;
          margin: 0;
          max-height: none;
          padding: $padding-lg;
      }

      .modal-footer {
          align-self: flex-end;
          margin: 0;
          padding: $padding-lg;
          width: 100%;
      }
  }

  @media screen and (max-width: $break-xs) {
      .modal-header,
      .modal-body,
      .modal-footer {
          padding: $padding-md;
      }
  }
}