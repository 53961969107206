//
// Buttons
// =============================================================================

%btn {
  @include button-base;
}

.btn {
  @extend %btn;
  &.btn-centered {
    margin: auto;
  }

  &.full,
  &.btn-full {
    width: 100%;
  }
}

@include button-size('md', $btn-md-font-size, $btn-md-padding-v, $btn-md-padding-h);

@include button-size('xs', $btn-xs-font-size, $btn-xs-padding-v, $btn-xs-padding-h, $responsive-block: false);
@include button-size('sm', $btn-sm-font-size, $btn-sm-padding-v, $btn-sm-padding-h);
@include button-size('lg', $btn-lg-font-size, $btn-lg-padding-v, $btn-lg-padding-h);
@include button-size('xl', $btn-xl-font-size, $btn-xl-padding-v, $btn-xl-padding-h);

@include button-style( 'blue',
  $bg: $blue-dark,
  $bg-dark: $navy
);

@include button-style('green',
  $bg: $green-base,
  $bg-dark: $green-dark
);

@include button-style( 'red',
  $bg: $red-base,
  $bg-dark: $red-dark
);

@include button-style( 'purple',
  $bg: $purple-base,
  $bg-dark: $purple-dark
);

@include button-style( 'orange',
  $bg: $orange-base,
  $bg-dark: $orange-dark
);

@include button-style( 'pink',
  $bg: $pink-base,
  $bg-dark: $pink-dark
);

@include button-style( 'yellow',
  $bg: $yellow-base,
  $bg-dark: $yellow-dark
);

@include button-style( 'teal',
  $bg: $teal-base,
  $bg-dark: $teal-dark
);

@include button-style( 'gray',
  $bg: $white,
  $bg-dark: $gray-bright,
  $border-color: $gray-light,
  $color: $text-color-base,
  $color-dark: $text-color-base
);

@include button-style( 'stroke',
  $bg: transparent,
  $bg-dark: rgba($blue-base, .2),
  $border-color: $blue-base,
  $color: $blue-base,
  $color-dark: $blue-dark
);

@include button-style( 'stroke-alt',
  $bg: transparent,
  $bg-dark: rgba($gray-light, .2),
  $border-color: $gray-light,
  $color: $gray-tinted,
  $color-dark: $gray-base
);

@include button-style( 'link',
  $bg: transparent,
  $bg-dark: transparent,
  $color: $blue-dark,
  $color-dark: $blue-dark
);

@include button-style( 'warn',
  $bg: transparent,
  $bg-dark: transparent,
  $color: $red-base,
  $color-dark: $red-dark
);

@include button-style( 'text',
  $bg: transparent,
  $bg-dark: transparent,
  $color: $gray-tinted,
  $color-dark: $gray-base
);

@include button-style( 'toggle',
  $bg: $white,
  $bg-dark: $blue-pale,
  $border-color: $gray-light,
  $color: $gray-medium,
  $color-dark: $gray-medium
);

@include keyframes(loading) {
  0% { opacity: 1 }
  25% { opacity: .2 }
  100% { opacity: 1 }
}

@include keyframes(loading2) {
  0% { opacity: 1 }
  50% { opacity: .2 }
  100% { opacity: 1 }
}

@include keyframes(loading3) {
  0% { opacity: 1 }
  75% { opacity: .2 }
  100% { opacity: 1 }
}

.btn-loading {
  color: $white;
  min-width: 97px;
  opacity: .6;
  .loading-dot {
    background-color: $white;
    border-radius: 15px;
    display: inline-block;
    height: $margin-xxs;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    vertical-align: middle;
    width: $margin-xxs;
    &:nth-child(1) {
      animation-name: loading;
    }
    &:nth-child(2) {
      animation-name: loading2;
      margin-left: 2px;
      margin-right: 2px;
    }
    &:nth-child(3) {
      animation-name: loading3;
    }
  }
}

.btn-success {
  background-color: $green-dark;
  color: $white;
  min-width: 97px;
  &:focus,
  &:hover {
    background-color: tint($green-dark, 30%);
  }
  .glyphicons {
    font-size: $font-size-lg;
    line-height: $line-height-xxs;
    margin-right: 0 !important;
  }
}

.toggle-group {
  @include clearfix();
  display: inline-block;

  &:not(.dynamic-width){
    @include display(flex);

    .btn.btn-toggle {
      @include flex(1 50%);
    }
  }

  &.glyphs-only {
    .glyphicons, .glyphicons-social, .glyphicons-filetypes {
      font-size: $font-size-lg;
      margin-right: 0px;
      vertical-align: middle;
    }
  }

  .btn.btn-toggle {
    display: inline-block;
    border-right: 0;
    border-radius: 0;
    cursor: pointer;
    float: left;
    margin-left: 0;
    text-align: center;

    &:first-of-type {
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
      &:active ~ .btn.btn-toggle:nth-of-type(2){
        border-left-color: $blue-base;
      }
    }

    &:nth-of-type(2) {
      &:active ~ .btn.btn-toggle:nth-of-type(3){
        border-left-color: $blue-base;
      }
    }

    &:nth-of-type(3):not(:last-of-type) {
      &:active ~ .btn.btn-toggle:nth-of-type(4){
        border-left-color: $blue-base;
      }
    }

    &:last-of-type {
      border-bottom-right-radius: 2px;
      border-right: 1px solid $gray-light;
      border-top-right-radius: 2px;
    }

    &:active,
    .active {
      border-color: $blue-base;
      border-left: 1px solid $blue-base;
    }

    &:hover {
      background-color: $blue-dark !important;
      border-color: $blue-dark !important;
      color: $white;
    }
  }

  .input-toggle {
    @extend .reader-only;

    &:checked {
      + .btn.btn-toggle {
        background: $blue-pale;
        border-color: $blue-base;

        &:before {
          content: "\E207";
          font-family: "Glyphicons Regular", "Lato", serif;
          margin-right: rem($margin-sm);
          vertical-align: bottom;
        }

        &.btn-xs:before {
          margin-right: $margin-xs/2;
        }

        &:first-of-type {
          ~ .btn.btn-toggle:nth-of-type(2){
            border-left-color: $blue-base;
          }
        }

        &:nth-of-type(2) {
          ~ .btn.btn-toggle:nth-of-type(3){
            border-left-color: $blue-base;
          }
        }

        &:nth-of-type(3):not(:last-of-type) {
          ~ .btn.btn-toggle:nth-of-type(4){
            border-left-color: $blue-base;
          }
        }
      }
    }
  }
}