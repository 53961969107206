﻿.ui-select-no-arrow {
    .ui-select-container.select2-container {
        .select2-choice {
            background-image: none !important;
            min-height: 38px;
            padding: $input-xs-padding-h $input-md-padding-h;

            .select2-search-choice-close {
                top: $font-size-sm;
                right: 38px;

                &:before {
                    content: none;
                }
            }
        }

        .select2-chosen > span {
            line-height: $dropdown-line-height;
        }
    }
}

.invert-bottom-pad {
    margin-bottom: -$margin-xs;
    padding-bottom: 0;
}

#GatewayInformation .gateway-option {
    margin-top:5px;
}

input.ng-invalid.suppress-error {
    border-color: $gray-light;
}

.host-name-info .input-label {
    margin-right: 5px;
}

.gateway-checkbox {
    padding-top: $margin-sm;
}

.contact-attribution-header {
    width: 92%;
}
.contact-attribution-row {
    width: 92%;
    margin-bottom: 15px;

    &.attributed-entity-row {
        float: left;
    }

    .attribution-type-select {
        height: 43px !important;

        &:disabled {
            pointer-events: none;
        }
    }
}

div.attributed-entity-row span.select2-chosen {
	min-height: 25px;
	line-height: 25px;
}

.contact-attribution-error {
	padding-top: 21px;
}

.contact-attribution-placeholder {
    margin: 15px 0px;
}

.delete-contact-attribution {
    float: right;
    margin: 5px 15px 0px 0px;
}