// 
// Alerts
// =============================================================================

.alert {
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: $border-radius-base;
  display: block;
  font-size: $font-size-base;
  line-height: $line-height-xs;
  margin-bottom: $margin-sm;
  padding: $btn-sm-padding-v ($btn-sm-padding-h / 2);
  position: relative;

  &.advanced {
    @include clearfix();
    .alert-icon {
      left: ($btn-sm-padding-h / 2);
      top: $btn-sm-padding-v;
      position: absolute;
    }
    .alert-content {
      display: inline-block;
      padding-left: $margin-base;
      & + .alert-content {
        display: block;
        margin-top: $margin-xxs;
      }
    }
  }
  
  &.default {
    background-color: $gray-light;
    color: $gray-dark;
  }

  &.info {
    background-color: $blue-light;
    color: $blue-dark;
  }

  &.success {
    background-color: $green-light;
    color: $green-dark;
  }

  &.warn {
    background-color: $orange-light;
    color: $orange-dark;
  }

  &.error {
    background-color: $red-light;
    color: $red-dark;
  }

  .alert-buttons {
    display: block;
    margin-top: $margin-xxs;
    text-align: right;
    a {
      padding: $margin-xxs;
    }
  }
  
  .dismiss {
    @include transform(translateY(-50%));
    color: inherit;
    cursor: pointer;
    font-size: 150%;
    font-weight: $font-weight-semibold;
    line-height: 0;
    position: absolute;
    right: ($btn-sm-padding-h / 2);
    top: 50%;
    .glyphicons {
      font-size: $font-size-md;
      vertical-align: top;
    }
  }
  
  p {
    margin: 0;
    padding: 0;
  }
}

.no-data-message {
  color: $gray-light;
  text-align: center;
  .btn {
    margin: 0 auto;
  }
  .glyphicons {
      font-size: $font-size-xl;
  }
  .no-data-title {
      color: $gray-tinted;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      padding: 0;
  }
}

.no-data-text {
  font-style: italic;
  color: $gray-tinted;
}