//
// Navbar tabs
// =============================================================================

.nav-tabs {
  @extend %list-unstyled;
  @include clearfix;

  > li {
    float: left;

    > a {
      border-bottom: 4px solid transparent;
      color: $gray-medium;
      display: block;
      padding: $margin-xs $margin-base;

      &:focus,
      &:hover {
        border-color: tint($blue-base, 20%);
      }
      @media screen and (max-width: $break-xs) {
        padding: ($margin-sm - 2px) $margin-sm;
      }
    }

    &.active {

      > a {
        border-bottom: 4px solid $blue-base;
      }
    }
  }
}


//
// Breadcrumbs
// =============================================================================

.breadcrumbs {
  @extend %list-unstyled;
  font-size: $font-size-sm;
  line-height: inherit;
  margin-bottom: $margin-sm * 1.5;

  > li {
    display: inline-block;
        
    &.active {
      color: $text-color-base;
      pointer-events: none;
      
      &:hover,
      a:hover {
        cursor: default;
      }
      
      a {
        color: inherit;
      }
    }
    
    & + li {
      
      &:before {
        color: $gray-light;
        content: "\/";
        padding: 0 rem($margin-xs);
      }
    }
  }
}


//
// Pagination
// =============================================================================

.pagination-container {
  text-align: center;

  .pagination-stats {
    font-size: $font-size-sm;
  }
}

.pagination {
  @extend %list-unstyled;
  @include clearfix;
  border-bottom: none;
  color: $link-color;
  display: inline-block;
  line-height: $line-height-lg;

  a {
    color: inherit;
    display: block;
    padding: 0 $margin-xxs;
  }

  .glyphicons {
    margin: 0;
    & + .glyphicons {
      margin-left: -$margin-xxs;
    }
  }
  
  li {
    display: block;
    float: left;

    &.active {
      background-color: $blue-base;
      border-color: $blue-base;
      border-radius: $border-radius-base;
      color: $white;
      
      &:focus,
      &:hover,
      a:hover {
        color: $white;
        cursor: default;
      }
    }
    
    &.disabled {
      color: $gray-light;
      
      &:focus,
      &:hover,
      a:hover {
        color: $gray-light;
        cursor: default;
      }
    }
  }
  
  .pages {
    @extend %list-unstyled;
    @include clearfix;
    border-radius: $border-radius-base;
    margin-bottom: -1px;
    margin-top: -1px;
    
    > li {
      
      &:last-child {
        
        > a {
          border-right: 0;
        }
      }
    }
  }
  
  &.no-border {
    
    li {
      text-align: center;
      &.active {
        background-color: $blue-base;
        color: $white;
      }
    }
    
    .pages {
      border-color: transparent;
      margin: 0 $margin-xs;
      
      > li {
        border-radius: 2px;
        &:focus,
        &:hover {
          background-color: transparent;
        }
        
        > a {
          border-right: 0;
          padding: 0 $margin-xxs;
        }
      }
    }
  }
}