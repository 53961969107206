﻿.hint-link {
    padding-top: 10px;
    padding-bottom: 10px;
}

.hint-text {
    max-width: 75%;
    padding-left: 24px;
}

.regulations-terms {
    padding-top: 0.5rem;
}

.regulations-link {
    text-decoration: underline;
}