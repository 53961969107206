.editor-options-right {
    @include clearfix;
    display: block;
    margin-bottom: $margin-sm;
    float: right;
    .btn {
        float: left;
    }
    .dropdown {
        margin-left: $margin-sm;
    }
    .dropdown-toggle {
        line-height: $line-height-xs;
        padding: $margin-xxs $input-xl-padding-h $margin-xxs $margin-sm;
    }
}
.editor-options-left {
    @include clearfix;
    display: block;
        margin-bottom: $margin-sm;
    float: left;
    .btn {
        float: right;
    }
    .dropdown {
        margin-right: $margin-sm;
    }
    .dropdown-toggle {
        line-height: $line-height-xs;
        padding: $margin-xxs $input-xl-padding-h $margin-xxs $margin-sm;
    }
}

.hide-validation-outline {
border-color: #B2C1DB !important
}

.panel-header {
    &.no-border {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: $margin-sm;
    }

    .panel-nav {
        &.left{
            float: left;
        }
        .dropdown {
            &.open {
                .dropdown-toggle {
                    background-color: transparent !important;
                }
            }

            .dropdown-toggle {
                line-height: $line-height-xs;
                padding: 0;

                &:after {
                    height: 0;
                    width: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid $link-color;
                    content: "";
                    margin-left: $margin-xxs;
                    padding-bottom: 2px;
                    color: inherit;
                    display: inline-block;
                }
                &:active,
                &:focus,
                &:hover {
                    &:after {
                        border-top-color: $link-color-hover;
                    
                    }
                }
            }
        }
    }
}

#AllReusablesTable {
  > tbody > tr > td {
    max-width: 400px;
  }
}

.text-left {
    text-align: left !important;
}
.lightgray {
    color: lightgray;
}

#Reusable-Html-Image-Upload {
    color: #444; 
    border: 1px solid $gray-light;
    box-shadow: 0 2px 0 0 #D9E3EF;
    display: inline-block;
    margin-left:0
}

