.filter {
  @extend %list-unstyled;
  @include clearfix;
  max-width: 300px;
  &.filter-list {
    &.no-border {
      .filter-row {
        border: none;
      }
    }
    .filter-row {
      @include clearfix();
      opacity: 1;
      > .checkbox-label {
        display: block;
        &:before {
          top: 50%;
          transform: translate(0%, -50%);
        }
        &:after {
          line-height: 0;
          top: 50%;
          transform: translate(0%, -50%);
        }
      }
      &:hover {
        cursor: default;
      }
    }
  }
}

.filter-row {
  line-height: $line-height-md;
  padding: $margin-sm 0;
  opacity: .5;
  &:hover {
    cursor: pointer;
  }
  &.active {
    opacity: 1;
  }
  & + .filter-row {
    border-top: 1px solid $gray-light;
  }
  & + .filter-row.active {
    border-top: 1px solid tint($gray-light, 50%);
  }
  .badge {
    float: right;
  }
}

.filter-name {
  display: inline-block;
  font-weight: $font-weight-semibold;

  .indicator + & {
    margin-left: $margin-sm;
  }
}