﻿$anchor-font-color: #008CBA !default;
$anchor-font-color-hover: scale-color($anchor-font-color, $lightness: -14%) !default;

:root {
    --neutralColor: #ced4da;
    --buttonColor: #228ae6;
    --contributionColor: #40c057;
    --primaryColor: #212529;
    --fontFamily: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
} 
@mixin element-invisible {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}
.headerItem {
    color: var(--primaryColor, #212529) !important;
}

// there is content on a default radio button that is cleared here
#SelectedRadioButton:after, #UnselectedRadioButton:after {
    content: "";
}

#SelectedRadioButton {
    accent-color: var(--submitHighlightHyperlinkColor, $blue-base);
}
#FirstNameInput::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--primaryColor, #212529) !important;
    opacity: 0.7 !important; /* Firefox */
}
#FirstNameInput::-moz-placeholder { /*  Firefox */
    color: var(--primaryColor, #212529) !important;
    opacity: 0.7 !important;
}

#FirstNameInput::-webkit-input-placeholder { /* Chrome */
    color: var(--primaryColor, #212529) !important;
    opacity: 0.7 !important;
}

#FirstNameInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--primaryColor, #212529) !important;
    opacity: 0.7 !important;
}

#FirstNameInput:-ms-input-placeholder { /* Microsoft Edge */
    color: var(--primaryColor, #212529) !important;
    opacity: 0.7 !important;
}
.at {
    .at-steps-bar {
        @extend %list-unstyled;
        @include display(flex);
        @include user-select(none);
        background: #f1f3f5;
        counter-reset: step 0;
        padding: 9px;
        border-radius: 5px ;

        a, b {
            color: inherit;
            display: block;
            padding: 3px 3rem;
            text-align: center;
            font-weight: 400;

            &:hover {
                color: gray;
            }
        }

        li {
            @include flex(1 auto);
            color: gray;
            margin-bottom: -9px;
            margin-top: -6px;
            padding: 9px 0;
            position: relative;

            &:last-of-type {
                > * {
                    border-right: 0;
                }
            }
        }
    }

    .at-step-neutral {
        border-bottom: 3px solid transparent;
        cursor: pointer;

        > *:before {
            @include transform(translateY(-50%));
            content: counter(step);
            counter-increment: step;
            position: absolute;
            text-align: center;
            left: 1.5rem;
            top: 50%;
            width: 22px;
            height: 22px;
            border: 1px solid transparent;
            border-radius: 20px;
            background-color: var(--neutralColor,#ced4da);
            color: var(--buttonColor, #228ae6);
            display: block;
            font-size: 0.8rem;
            font-weight: 400;
            z-index: 1;
        }

        > #CompletedStep:before {
            @include transform(translateY(-50%));
            content: counter(step);
            counter-increment: step;
            position: absolute;
            text-align: center;
            left: 1.5rem;
            top: 50%;
            width: 22px;
            height: 22px;
            border: 1px solid transparent;
            border-radius: 20px;
            background-color: var(--contributionColor, #40c057);
            color: var(--buttonColor, #228ae6);
            display: block;
            font-size: 0.8rem;
            font-weight: 400;
            z-index: 1;
        }

        > *:focus {
            &:before {
                border-color: rgba(0,0,0,0.25);
            }
        }

        &.valid {
            > *:before {
                background: tint(green, 50%);
            }
        }

        &.active {
            border-bottom-color: blue;

            > * {
                color: gray;

                &:before {
                    color: var(--buttonColor, #228ae6);
                }
            }

            &.valid {
                > *:before {
                    background: green;
                }
            }

            &.warn {
                > *:before {
                    background: orange;
                }
            }

            &.invalid {
                > *:before {
                    background: red;
                }
            }
        }

        &.invalid {
            > *:before {
                background: tint(red, 50%);
            }
        }

        @media screen and (max-width: 32rem) {
            a, b {
                padding: 20px 0 0;
                font-size: 0.75rem;

                &:before {
                    @include transform(translateY(-100%) translateX(-50%));
                    left: 50%;
                    right: auto;
                }
            }
        }
    }
}
.label-amounts {
    width: 22%;
    height: auto;
    margin-right: 4%;
    float: left;
    color: var(--buttonColor, #228ae6) !important;
    font-size: 24px !important;
    background-image: none;
    margin-bottom: 15px;
    letter-spacing: -0.03em;
    line-height: 1.428571429;
    display: block;
    cursor: pointer;
    padding-top: 6px; 
    @include user-select(none);
    text-align: center;
    text-shadow: -1px -1px 1px rgba(darken($green-base, 20%), 0.1);
    font-weight: 400;
    white-space: nowrap;
    position: relative;
    z-index: 1;

    &.label-otheramount {
        background-color: transparent;
        font-size: 0;
        padding: 0;
    }

    &:nth-child(4n+4) {
        margin-right: 0;
    }

    &.label-otheramount {
        width: 48%;
        margin-right: 0;
        position: relative;
    }

    &.label-otheramount-recurring {
        width: 100%;
        margin-right: 0;
        position: relative;
    }

    input {
        width: 100% !important;
        font-size: $font-size-base;
        margin-left: 0;
        height: 50px;
        min-height: 0;
        padding: 7px 10px 7px 1.5rem;
        outline: none;
        border: 1px solid #BBB;
        border-radius: $border-radius-base;
        background-color: $input-bg;
        font-size: 24px;
        line-height: 1.428571429;
        font-weight: 400;
        letter-spacing: -0.03em;
        max-width: 100%;
        margin-bottom: 0;

        & + .label-otheramount-prefix {
            display: block;
            position: absolute;
            color: $gray-light;
            left: 0;
            top: 0;
            font-size: 24px;
            line-height: 0.9;
            padding: 7px 0 7px 10px;
            border: 1px solid transparent;
            text-shadow: none;
        }

        &:required {
            color: #fff;
            border-color: $green-dark; //#28A95F;
            background-color: $green-base; //#2ECC71;
            box-shadow: none;
            @include selection($green-dark);

            @include placeholder {
                color: rgba($green-light, 0.5);
            }

            & + .label-otheramount-prefix {
                color: #fff;
            }

            &:focus {
                box-shadow: 0 0 5px $blue-base;
            }
        }
    }

    &.error {
        input:required {
            color: $red-dark;
            border-color: #f04124;
            background-color: $input-bg;
            @include selection(#fff);

            @include placeholder {
                color: $red3;
            }

            & + .label-otheramount-prefix {
                color: $red-dark;
            }

            &:focus {
                box-shadow: 0 0 5px rgba(#f04124, 0.5);
            }
        }
    }

    &.long {
        font-size: 20px;
        line-height: 1.7142857148;
    }

    &.veryLong {
        font-size: 18px;
        line-height: 1.9047619053;
    }

    &.incrediblyLong {
        font-size: 16px;
        line-height: 2.14285714346;
    }

    &.tooLong {
        font-size: 16px;
        white-space: normal;
        line-height: 1.12;
    }

    > a {
        position: absolute;
        top: 0;
        right: 0;
        height: 50px;
        left: 0;
        display: block;
        background-color: tint($green-base, 50%); //#93E1B4;
        border-radius: $border-radius-base;
        z-index: -1;
    }

    &:hover > a {
        background-color: tint($green-base, 40%); //#78DAA1;
        border-color: tint($green-base, 20%);
    }

    &:active > a {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.125) inset;
    }


    input[type="radio"] {
        @include element-invisible;

        &:checked {
            + a {
                border-color: $green-dark; //#28A95F;
                background-color: $green-base; //#2ECC71;
            }
        }

        &:focus {
            + a {
                box-shadow: 0 0 5px #999999;
            }
        }
    }
}
#LastNameError {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

#Caption {
    margin-bottom: 1.3rem !important;
}
.header {
    margin-bottom: 1.3rem !important;
    color: #707F9A !important;
} 

#ContactInformation-LastName {
    font-family: var(--fontFamily, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif) ;
}

#UnselectedRadio:before {
    border-color: var(--neutralColor, #ced4da);
}

.checkbox,
.radio {
    left: -9999em;
    position: absolute;

    ~ label {
        cursor: pointer;
        float: left; // or line breaks will be dumb
        padding-left: $font-size-lg + $margin-sm;
        position: relative;

        &:before {
            @include size($font-size-lg);
            background: transparent;
            border-radius: $border-radius-base;
            border: 1px solid $gray-light;
            color: inherit;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            top: 3px;
        }

        &:after {
            @include transition(all .05s ease-in-out);
            color: $white;
            content: "\E207";
            display: block;
            font-family: 'Glyphicons Regular';
            font-size: $font-size-sm;
            line-height: 1;
            left: 3px;
            position: absolute;
            top: 5px;
        }
    }

    &:checked,
    &.checked {

        ~ label {

            &:before {
                background-color: $blue-base;
                border-color: $blue-base;
            }

            &:after {
                color: $white;
                opacity: 1;
                transform: scale(1);
            }
        }

        ~ .labeled {
            &:before {
                background-color: var(--submitHighlightHyperlinkColor, $blue-base);
                border-color: var(--neutralColor, #ced4da);
                border-width: medium;
            }

            &:after {
                color: $white;
                opacity: 1;
                transform: scale(0);
            }
        }
    }


    &:hover,
    &.hover {

        ~ label {

            &:before {
                border: 1px solid $blue-base;
            }
        }

        &:checked,
        &.checked {

            + label {

                &:before {
                    border-color: $blue-dark;
                }
            }
        }
    }

    &:focus,
    &.focus {

        ~ label {

            &:before {
                border-color: $blue-base;
                box-shadow: 0 0 4px 0 $blue-base;
            }
        }
    }

    &:active,
    &.active {

        ~ label {

            &:before {
                background-color: $blue-dark;
                border-color: $blue-dark;
            }

            &:after {
                color: $white;
            }
        }
    }

    &[disabled],
    &.disabled {

        ~ label {
            cursor: default;

            &:before {
                background-color: $gray-pale;
                border-color: $gray-light;
            }

            &:after {
                color: $gray-light !important;
            }
        }
    }
}


.headerItem {
    margin-bottom: 1.3rem !important;
}


.designForm {
    flex: 1 !important;
    border: 1px lightgrey solid ;
    padding: 1rem !important;
}
#HeaderSamplePanel {
    border-bottom: 1px lightgrey solid ;
}

.label-amounts {
    [title~="Selected"] {
        padding-top: 6px !important;
    }

    input {
        padding: 7px 10px 7px 0.5rem !important;
        height: 50px !important;
        font-size: 1.5rem !important;
    }

    span.label-otheramount-prefix {
        opacity: 0.3 !important;
    }
}
#UnselectedButton {
    border-radius: 6px ;
    opacity: 0.5 ;
}
#SelectedButton {
    border-radius: 6px ;
}
#ContributionInformation {
    border-bottom: 1px lightgrey solid ;
    padding: 0 0 1rem 0 ;
}
#FormTitle {
    margin-bottom: 0.5rem ;
}
.radioGroup {
    display: inline-block ;
}
.at-text {
    
    border-bottom: 1px lightgrey solid !important;
}

#Hyperlink {
    text-decoration: underline ;
}
.FirstName {
    margin: 0 !important;
}
#FirstNameInput {
    margin: 0 0 4rem 0;
}

.design-instruction-list {
    margin-top: 8px;
    margin-bottom: 24px;

    li {
        padding-left: 8px;
        padding-bottom: 4px;

        &::marker {
            color: #0D98E6;
        }
    }
}

.popover-label-trigger-text {
    padding-left: 12px;
    text-decoration: underline;
    text-decoration-style: dotted;
    color: $gray-tinted;
    font-size: $font-size-sm;
}

.popover {
    max-width: 500px;
}

.popover-content {
    text-align: center;
    max-width: 350px;
    min-height: 210px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

.popover-description {
    font-weight: 400;
    font-size: 14px;
    color: #1D1C1D;
    float: left;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
}


.designForm.at {
    .panel-border-top {
        border-top: 1px solid lightgrey;
    }

    .panel-card {
        border-radius: 4px;
        border: 2px solid #CED4DABF;
        padding: 1rem;
        height: 13rem;
    }

    .panel-label {
        font: 21px;
        margin: 0.5rem 0 1rem;
        color: #707F9A;
    }

    .icon-container {
        margin-right: 0.5rem;
        position: relative;
        border: 2px solid lightgrey;
        background-size: cover;
        display: inline-block;
        height: 1.8rem;
        width: 1.8rem;
        vertical-align: middle;
    }

    .panel-icon {
        margin-top: 0;
        font-size: 16px;
        right: 20%;
        top: 1%;
        position: absolute;
        font-style: normal;
    }

    .panel-icon-pencil {
        background-size: cover;
        border-radius: 50%;
        display: inline-block;
        height: 2rem;
        width: 2rem;
        vertical-align: middle;
        font-style: normal;
        margin-left: 10px;
    }

    .avatar-profile-picture {
        position: relative;
        background-size: cover;
        border-radius: 50%;
        display: inline-block;
        margin: 0 1rem 0 0;
        height: 36px;
        width: 36px;
        vertical-align: middle;
    }

    .avatar-profile-initial {
        position: absolute;
        top: 17%;
        left: 25%;
    }

    .avatar-profile-info {
        width: fit-content;
    }

    .avatar-name-info {
        height: 3.5rem;
    }

    .navigation-panel {
        margin: 2rem 0 0;
        border-bottom: 1px lightgrey solid;
        border-top: 1px lightgrey solid;
        padding-bottom: 1.5rem;
    }

    .navigation-steps {
        margin-bottom: 2rem;
        display: -webkit-box;
        background: transparent;
    }

    .contact-form {
        display: block;
        padding: 0;
    }

    .btn-at.panel-save-btn {
        margin-right: 10px;
        padding: 7.5px 14px;
        gap: 10px;
        width: 61px;
        height: 41px;
    }

    .icon-panel-social {
        margin-top: 3rem;
        display: inline-flex;
        height: 3.5rem;
    }

    .icon-panel {
        height: 3.5rem;
        margin: 0.5rem 0 0 25%;
    }

    .icon-panel-pencil-selected {
        position: absolute;
        font-size: 16px;
        right: 0%;
        top: 9%;
    }

    .btn-at.panel-cancel-btn {
        border: 1px solid #CED4DA;
        padding: 7.5px 14px;
        width: 73px;
        height: 41px;
    }

    .panel-margin-right {
        margin-right: 3.5rem;
    }

    .panel-card-avatars {
        padding: 1.5rem 1.5rem;
    }

    .steps-border {
        border-bottom: 4px solid;
    }

    .panel-left-margin {
        margin-left: 1rem;
    }
}
#HeaderPagesSamplePanel {
    width: inherit;
    overflow: hidden;
    margin-bottom: 2.5rem;
}
#PagesSamplePanel {
    border-radius: 4px;
}
.primary-color {
    background-color: #3b5bdb;
}
.contribution-color {
    background-color: #40c057;
}
.action-color {
    background-color: #fa5252;
}