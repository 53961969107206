﻿form.previewForm {
    height: 85vh;
    overflow-y: auto;
}

.previewForm .text-center {
    text-align: center;
}

.previewForm .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.previewForm button.accordion {
    background-color: white;
    color: #444;
    cursor: pointer;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    top: 5px;
    position: relative;
}

.previewForm button.accordion:after {
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.previewForm div.fields {
    padding-top: 10px;
    background-color: white;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.previewForm .panel-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 8px;
}

.previewForm .btn {
    margin-bottom: 0;
}

.previewForm button.accordion {
    top: 4px;
    padding: 0;
}

.previewForm .input-unit {
    margin-bottom: 8px;
}

.previewForm .fields {
    padding-top: 0;
}

.previewForm .fields .panel-content {
    padding: 12px 0;
}
