@include grid-setup('full', $grid-columns);
@include grid-setup('half', $grid-columns / 2);


.grid-full,
.grid-half {
  @include clearfix;
}

.row,
[class^="row"] {
  @include clearfix;
  &.row {
    padding-bottom: $margin-base * 1.5;
    &.no-margin {
      padding-bottom: 0;
    }
    &.pushed {
      margin-top: $margin-base;
    }
    & + .row.bordered {
      border-top: 1px solid $gray-bright;
      padding-top: $margin-base * 1.5;
    }
  }
}


.col-full {
  @include span-columns($grid-columns of $grid-columns);
}
.col-half {
  @include span-columns($grid-columns/2 of $grid-columns);
}
.col-third {
  @include span-columns($grid-columns/3 of $grid-columns);
}
.col-fourth {
  @include span-columns($grid-columns/4 of $grid-columns);
}
.col-fifth {
  @include span-columns($grid-columns/5 of $grid-columns);
}
.col-sixth {
  @include span-columns($grid-columns/6 of $grid-columns);
}
.col-eighth {
  @include span-columns($grid-columns/8 of $grid-columns);
}


.row-golden {
  .col-narrow {
    @include span-columns(9 of $grid-columns);
  }
  .col-wide {
    @include span-columns(15 of $grid-columns);
  }
}
.row-halves {
  
  .col-left,
  .col-right {
    @include span-columns(12 of $grid-columns);
  }
}
.row-thirds {
  .col-left,
  .col-center,
  .col-right {
    @include span-columns(8 of $grid-columns);
  }
}