﻿.side-tab {
    padding-top: 12px;
    padding-bottom: 6px;
}

.padding-left-lg {
    padding-left: 32px;
}

.padding-bottom-sm {
    padding-bottom: 8px;
}

.padding-bottom-md {
    padding-bottom: 12px;
}

.margin-bottom-md {
    margin-bottom: 12px;
}
