//
// Utilities
// =============================================================================

.no-decoration {
  &:active,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}

.item-info {
  & > a {
    display: block;
    line-height: $line-height-sm;
  }
  .item-sub-info {
    color: $gray-tinted;
    display: block;
    font-size: $font-size-sm;
    .item-status {
      font-weight: $font-weight-bold;
    }
  }
}

.padded {
  padding: $margin-xs $margin-sm;
}

.padded-left {
  padding-left: $margin-base;
  @media screen and (max-width: 1024px) {
    padding-left: 0;
  }
}

.padded-sides {
  padding: 0 $margin-base;
}

.pull-right {
  float: right;
}

%reader-only {
  @include size(1px);
  clip: rect(1px, 1px, 1px, 1px);
  left: -9999em;
  overflow: hidden;
  position: absolute !important;
}

.reader-only {
  @extend %reader-only;
}

.flip-container {
  .flipper {
    position: relative;
  }
  .back,
  .front {
    @include prefixer(backface-visibility, hidden, webkit moz ms);
    transition: 0.6s;
    transform-style: preserve-3d;
    left: 0;
    top: 0;
  }

  .back {
    position: absolute;
    transform: perspective(800px) rotateY(180deg);
    width: 100%;
    z-index: 2;
    @include color-variation('blue', $blue-base);
    @include color-variation('orange', $orange-base);
    @include color-variation('pink', $pink-base);
    @include color-variation('purple', $purple-base);
    @include color-variation('red', $red-base);
    @include color-variation('teal', $teal-base);
    @include color-variation('yellow', $yellow-base);
  }

  .front {
    position: relative;
    transform: perspective(800px) rotateY(0deg);
    z-index: 1;
  }

  &:focus,
  &:hover,
  &.hover {
    .back {
      transform: perspective(800px) rotateY(0deg);
    }
    
    .front {
      transform: perspective(800px) rotateY(-180deg);
    }
  }
}


.inline-block {
  display: inline-block;
}


