//
// Form borders, margins, and padding
// =============================================================================

.form {
  background: $form-bg;
  border-radius: $form-border-radius;
  
  .flex {
    margin-right: 0 !important;
  }
}

.form-header {
  border: $form-border;
  border-top-left-radius: $form-border-radius;
  border-top-right-radius: $form-border-radius;
}

.form-header,
.form-description,
.form-footer,
.form-fields {
  padding: $form-padding;
}

.form-column {
  border-bottom: $form-border;
  border-left: $form-border;
  border-right: $form-border;
  border-bottom-left-radius: $border-radius-base;
  margin-right: -1px;

  &:last-child {
    border-bottom-right-radius: $border-radius-base;
    margin-right: 0;
    padding-right: 0 !important;
  }
  
  @media screen and (max-width: $break-sm) {
    border-radius: 0;
    margin-right: 0;
  }
}

.form-steps {
  background-color: $form-steps-bg;
}

.form-login {
  border-bottom: $form-border;
  padding-bottom: $form-padding;
}

.form-fieldset {
  border-color: $form-border-color;
  border-style: $form-border-style;
  border-width: 0 0 1px;
  padding: 0 0 $form-padding;
}


//
// Form headings & legends
// =============================================================================

.form-title,
.form-legend {
  color: $heading-color;
  font-family: $heading-font-family;
  font-weight: $font-weight-normal;
}

.form-title {
  font-size: $font-size-h5;
  text-align: center;
}

.form-legend {
  font-size: $font-size-h6;
  padding-bottom: $form-padding;
  padding-top: $form-padding;

  .glyphicons {
    margin-left: $margin-sm;
  }
}


//
// Form footer & buttons
// =============================================================================

.form-footer {
  padding-top: 0;

  small {
    display: block;
    padding-top: $form-padding;
  }
}

.form-buttons,
.form-login {

  .btn {
    display: inline-block;
    margin-bottom: 0;

    + a {
      margin-left: $margin-xs;
    }
  }
}

.form-buttons {
  text-align: right;

  .btn {
    margin-left: $margin-sm;
  }

  @media screen and (max-width: $break-xs) {

    .btn {
      margin-bottom: $margin-sm;
      margin-left: 0;
      width: 100%;
    }
  }
}


//
// Form login
// =============================================================================

.form-login {
  @include clearfix;

  br {
    display: none;
  }

  .form-login-description {
    padding: $margin-xxs 0;

    .small {
      display: inline-block;
      line-height: $line-height-md;

      + .small {
        margin-left: $margin-xxs;
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {

    br {
      display: block;
    }

    .form-login-action {
      float: right;
    }

    .form-login-description {
      float: left;
    }
  }
}