// 
// Dropdown
// ============================================================================

.dropdown {
  display: block;
  float: left;
  position: relative;

  &.action-menu {
    button {
      box-shadow: $input-select-box-shadow;
      color: $gray-tinted;
      line-height: $line-height-base;
      margin-bottom: 0;
      padding: $btn-xs-padding-v $btn-xs-padding-h;
    }

    &.open {
      button {
        background-color: $blue-pale;
        border-color: $blue-base;
        box-shadow: 0 2px 0 0 $blue-base;
      }
    }

    ul.dropdown-menu {
      > li {
        line-height: $line-height-xs;
      }
    }
  }

  .dropdown-toggle {
    display: block;
    line-height: $line-height-xl;
    padding: $margin-sm $margin-base;

    > {
      .badge,
      .glyphicons,
      .p-icon {
        margin: 0;
        vertical-align: middle;
      }
    }
  }

  [data-toggle="dropdown"] {
    margin-bottom: 0;
    position: relative;
  }

  &.dropdown-icon {
    @include clearfix;

    .glyphicons,
    .p-icon {
      &:before {
        margin-right: rem(-$font-size-base / 2);
        margin-top: rem($font-size-base / 2);
      }
    }
  }
  &.open {
    .dropdown-toggle {
      background-color: $blue-dark;
    }
  }
}

.dropdown-menu .disabled > a > div:first-child {
    color: lightgray !important;
}


ul.dropdown-menu {
  @extend .list-unstyled;
  background-color: $dropdown-bg;
  border: rem(1px) solid $dropdown-border-color;
  box-shadow: $dropdown-box-shadow;
  display: none;
  float: left;
  font-size: rem($font-size-base);
  left: 0;
  line-height: $line-height-base;
  list-style: none;
  min-width: rem($dropdown-width);
  padding-bottom: $dropdown-link-padding-v;
  padding-top: $dropdown-link-padding-v;
  position: absolute;
  top: 100%;
  z-index: 999;

  &.dropdown-menu-icons {
    border-top: none;
    padding: 0;
    > li {
      border-top: 1px solid $gray-bright;
      border-right: 1px solid $gray-bright;
      float: left;
      text-align: center;
      width: 50%;
      &:first-child {
        border-top: none;
      }
      &:nth-child(2){
        border-top: none;
      }
      &:nth-child(2n + 2) {
        border-right: none;
      }
      > a {
        padding: ($margin-sm * 1.5) 0;
        > div {
          color: $gray-medium;
          font-size: $font-size-sm;
          font-weight: $font-weight-semibold;
          line-height: $line-height-xs;
          margin: 0;
          &:first-child {
            color: $blue-base;
            font-size: $font-size-xl;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }

  &.dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .active {
    pointer-events: none;
    a {
      border-left: 4px solid $blue-base;
      color: $gray-medium;
      font-weight: $font-weight-bold;
      padding-left: $margin-base - 4px;
    }
  }

  .open > & {
    display: block;
  }

  > li {
    position: relative;
    
    &:focus,
    &:hover {
      background-color: $gray-pale;
    }

    > a {
      @include clearfix;
      color: $link-color;
      display: block;
      padding:  $dropdown-link-padding-v $dropdown-link-padding-h;
      white-space: nowrap;
    }

    &.divider {
      margin-bottom: rem(floor($line-height-sm - ($dropdown-link-padding-v * 2)) - 1);
      margin-top: rem(floor($line-height-sm - ($dropdown-link-padding-v * 2)));
      overflow: hidden;
    }

    &.dropdown-header {
      background: $gray-pale;
      color: $dropdown-heading-color;
      font-size: rem($dropdown-heading-size);
      margin: rem(floor($line-height-sm - ($dropdown-link-padding-v * 2))) 0;
      padding: rem($margin-sm) rem($dropdown-link-padding-h);

      img {
        display: inline-block;
        margin-right: $margin-xxs;
        vertical-align: text-bottom;
      }

      &:first-child {
        margin-top: rem(floor($line-height-sm - ($dropdown-link-padding-v * 2)) * -1 + 1);
      }
    }
  }
}

//
//  User Dropdown
//  ============================================================

.dropdown-user {

  > .dropdown-toggle {
    display: block;
    line-height: $margin-base * 2;

    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    > {
      .username-info {
        font-size: rem($font-size-sm);
        margin-top: -4px;
      }
    }
  }

  .profile-info,
  .username-info {
    display: inline-block;
    line-height: $margin-sm * 1.5;
    vertical-align: middle;
  }

  .profile-info {
    text-align: right;
  }

  .profile-name {
    color: $white;
  }

  .profile-picture {
    background-color: tint($blue-base, 60%);
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    margin-left: $margin-sm * 1.5;
    @include size($margin-base * 1.5);
    vertical-align: middle;
    & > span {
      color: $white;
      font-size: $font-size-lg;
      margin-right: 0;
      padding: $margin-xs;
    }
  }

  .sub-info {
    color: tint($blue-base, 70%);
    font-size: rem($font-size-xs);
    line-height: $line-height-xs;
  }

  .username {
    color: $white;
    font-size: $font-size-base;
    line-height: $line-height-xs;
  }

  .user-item {

    padding-left: rem($dropdown-link-padding-h);
    padding-right: rem($dropdown-link-padding-h);
    white-space: nowrap;

    .profile-info {

    }

    .profile-picture {
      margin-right: rem($margin-xxs);
    }

    .switch-context {
      display: inline-block;
      float: right;
      padding-left: $margin-sm;
      padding-right: $margin-sm;
    }
  }
  @media screen and (max-width: $break-xs) {
    .dropdown-toggle {
      padding-left: $margin-xs;
      padding-right: $margin-xs;
    }
    .profile-info {
      display: none;
    }
    .profile-picture {
      margin-left: 0;
    }
  }
}

//
//  Dropdown App Switcher
//  ===============================================================

.dropdown-app-switcher {
  &.open {
    .dropdown-toggle {
      color: $white;
    }
  }
  .dropdown-toggle {
    color: tint($blue-base, 60%);
    @media screen and (max-width: $break-xs) {
      padding-left: $margin-xs;
      padding-right: $margin-xs;
    }
  }
}

div.dropdown-app-switcher {
  &.open {
    .dropdown-toggle {
      background-color: tint($gray-tinted, 60%);
    }
  }
  .dropdown-toggle {
    color: $white;
  }
}